@import './../theme/variables.scss';
html {
  overflow-x: hidden;
}
body {
  overflow-x: hidden;
}

.follow_mobile-link{
  font-size: 12px !important;
}

.card-info {
  background: rgba(251, 188, 5, 0.1);
  border-radius: 4px;
  padding: 10px 20px 10px;
  .card-info-inner {
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    color: #FBBC05;
    display: flex;
    align-items: center;
  }
  .card-info-icon {
    min-width: 27px;
    margin-right: 18px;
  }
}

.report__search--global {
  box-sizing: border-box;
  font-size: 14px !important;
  background-color: white;
  background-image: url('/img/search-icon.svg');
  background-position: 16px 17px;
  background-repeat: no-repeat;
  padding: 13px 20px 13px 50px !important;
  border-radius: 4px !important;
  height: 54px !important;
  border: 1px solid #CED0DA !important;
  margin-bottom: 0px !important;
  width: 100%;
  border: 2px solid #e5e5e5 !important;
  &:focus {
    border: 2px solid $secondaryColor !important;
    outline: 0;
  }
  @media #{$phone}{
    margin-bottom: 10px !important;
    width: 100% !important;
  }
}

.no__hr div + div {
  left: 0 !important;
  margin-left: 0px !important;
  padding-right: 0 !important;
  width: calc(100% + 17px) !important;
  top: -1px !important;
  height: 54px !important;
  &:focus {
    border: 2px solid $secondaryColor;
    outline: 0;
  }
}
.no__hr {
  padding: 0px 15px !important;
  #placeSearchBoxField {
    margin-bottom: 10px;
    padding-left: 0 !important;
  }
  hr {
    display: none;
  }
  button {
    opacity: 0;
  }
}
.phonefull__input {
  padding: 5px 0 !important;
  .selected-flag {
    padding: 0 0 0 15px !important;
  }
}
.close__reports {
  margin-top: 16px !important;
}
// .Select.is-clearable.is-focused.is-open.is-searchable.Select--multi {
//   border: 2px solid $secondaryColor !important;
//   outline: 0;
// }
.Select.is-focused > .Select-control {
  border: 2px solid $secondaryColor !important;
  border-color: $secondaryColor !important;
  outline: 0;
  box-shadow: none !important;
}
.modalsub__btn {
  .update__town__btn {
    padding: 12px 20px;
    width: 100%;
    font-family: 'Pier Sans' !important;
    font-weight: bold;
    font-size: 14px;
    color: #FFFFFF;
    letter-spacing: 0.75px;
    text-align: center;
    background: $secondaryColor;
    outline: none;
    border: 0;
    border-radius: 3px;
    margin: 0;
  }
}
.labelcapital__global {
  text-transform: uppercase;
}
.labelinputs__global {
  width: 100%;
  font-weight: 600;
  font-size: 14px;
  color: $primaryColor;
  margin: 0 0 5px 0;
}
.textinputs__global {
  width: 100%;
  padding: 5px 15px;
  border: 2px solid #e5e5e5;
  background: #fff;
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px !important;
  height: 54px !important;
  border-radius: 4px;
  &:focus {
    border: 2px solid $secondaryColor;
    outline: 0;
  }
  #flagInputControlMainPhone {
    // margin-left: 40px !important;
    // width: 84% !important;
    // height: 50px;
    // border: 0;
    // outline: 0;
    // position: relative;
    // top: -5px;
    // right: -15px;
    margin-left: 0px !important;
    width: 100% !important;
    height: 50px;
    border: 0;
    outline: 0;
    position: relative;
    top: -5px;
    right: 0px;
    padding-left: 50px;
    left: 0;
    &:focus {
      border: 2px solid $secondaryColor;
      outline: 0;
      height: 54px;
      top: -7px;
      left: -2px;
      right: -2px;
      width: calc(100% + 4px) !important;
      border-radius: 4px;
    }
  }
  #flag-dropdown {
    background: none !important;
    border: 0 !important;
    border-bottom: 0px solid #9b9b9b;
    height: 40px !important;
  }
  .flagInputControlMainPhone {
    // margin-left: 40px !important;
    // width: 84% !important;
    // height: 50px;
    // border: 0;
    // outline: 0;
    // position: relative;
    // top: -5px;
    // right: -15px;
    margin-left: 0px !important;
    width: 100% !important;
    height: 50px;
    border: 0;
    outline: 0;
    position: relative;
    top: -5px;
    right: 0px;
    padding-left: 50px;
    left: 0;
    &:focus {
      border: 2px solid $secondaryColor;
      outline: 0;
      height: 54px;
      top: -7px;
      left: -2px;
      right: -2px;
      width: calc(100% + 4px) !important;
      border-radius: 4px;
    }
  }
}

.is-focused.sel__review {
  border: 2px solid $secondaryColor !important;
  outline: 0;
  border-radius: 4px;
}
.sel__review {
  &:focus {
    outline: 0;
  }
  .Select-control {
    padding: 0 !important;
    height: 54px;
    border: 2px solid #e5e5e5 !important;
    cursor: pointer;

    .Select-placeholder {
      text-align: left;
      height: 54px;
      line-height: 54px !important;
      padding: 0 15px;
      font-size: 14px;
    }
    .Select-input {
      margin-left: 0;
      padding: 0;
      width: 100%;
    }
    .Select-arrow-zone {
      .Select-arrow {
        position: relative;
        &::after {
          content: '';
          position: absolute;
          right: -7px;
          top: -10px;
          opacity: 100;
          width: 20px;
          height: 20px;
          background:#fff url('/img/custom-select-arrow.svg');
          background-size: 12px;
          background-repeat:no-repeat;
          background-position: center center;
        }
      }
    }
    .Select-multi-value-wrapper {
      width: calc(100% + 26px);
      height: 54px;
      padding: 0 15px;
      margin-left: -15px;
      // input {
      //   width: 100% !important;
      //   padding: 0px 0px;
      //   border: 2px solid #e5e5e5;
      //   background: #fff;
      //   color: rgba(0, 0, 0, 0.87);
      //   font-size: 14px;
      //   height: 50px;
      //   border-radius: 4px;
      //   line-height: 50px;
      //   font-size: 14px;
      //   background:#fff url('/img/custom-select-arrow.svg');
      //   background-size: 12px;
      //   background-repeat:no-repeat;
      //   background-position: calc(100% - 15px);
      //   appearance: none;
      //   -moz-appearance: none;
      //   -o-appearance: none;
      //   -webkit-appearance: none;
      //   -ms-appearance: none;
      //   cursor:pointer;
      //   &:focus {
      //     border: 2px solid $secondaryColor;
      //     outline: 0;
      //   }
      // }
    }
  }
}
.textareainputs__global {
  width: 100%;
  border: 2px solid #e5e5e5;
  background: #fff;
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  height: 54px;
  border-radius: 4px;
  resize: none;
  line-height: 16px;
  padding: 16px 15px;
  height: 160px;
  &:focus {
    border: 2px solid $secondaryColor;
    outline: 0;
  }
}
.selectinputs__global {
  width: 100% !important;
  border: 2px solid #e5e5e5;
  background: #fff;
  color: rgba(0, 0, 0, 0.87);
  height: 54px !important;
  border-radius: 4px;
  resize: none;
  line-height: 19px !important;
  padding: 16px 15px;
  font-size: 14px !important;
  background:#fff url('/img/custom-select-arrow.svg');
  background-size: 12px;
  background-repeat:no-repeat;
  background-position: calc(100% - 15px);
  appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  cursor:pointer;
  &:focus {
    border: 2px solid $secondaryColor;
    outline: 0;
  }
}
.react-datepicker__input-container {
  &:focus {
    border: 2px solid $secondaryColor;
    outline: 0;
  }
}
.selectdate__global {
  width: 100%;
  border: 2px solid #e5e5e5;
  background: #fff !important;
  color: rgba(0, 0, 0, 0.87) !important;
  text-align: left !important;
  height: 54px;
  border-radius: 4px !important;
  resize: none;
  line-height: 19px;
  padding: 16px 15px 16px 70px !important;
  font-size: 14px !important;
  background-image: url('/img/custom-select-arrow.svg'), url("/img/calendar-icon.svg") !important;
  background-position: calc(100% - 15px), 19px center !important;
  background-repeat: no-repeat, no-repeat !important;
  background-size: 12px, 30px !important;
  appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  cursor:pointer;
  &:focus {
    border: 2px solid $secondaryColor;
    outline: 0;
  }
}

.selecttime__global {
  width: 100%;
  border: 2px solid #e5e5e5;
  background: #fff !important;
  color: rgba(0, 0, 0, 0.87) !important;
  text-align: left !important;
  height: 54px;
  border-radius: 4px !important;
  resize: none;
  line-height: 19px;
  padding: 16px 15px 16px 70px !important;
  font-size: 14px !important;
  background-image: url('/img/custom-select-arrow.svg'), url("/img/time_img.svg") !important;
  background-position: calc(100% - 15px), 19px center !important;
  background-repeat: no-repeat, no-repeat !important;
  background-size: 12px, 30px !important;
  appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  cursor:pointer;
  &:focus {
    border: 2px solid $secondaryColor;
    outline: 0;
  }
}




.date__picker--modal{
  .modal-body{
    padding: 30px 18px;
    .datepicker__title{
      font-family: Neue Montreal;
      font-weight: 900;
      color: #4a4a4a;
      letter-spacing: 1.33px;
      margin-bottom:32px;
    }
    .rdr-DateRange{
      .rdr-Calendar{
        .rdr-Days{
          .rdr-Day{
            &.is-selected.is-inRange{
              background: #5BC0BE!important;
            }
            &.is-selected{
              background: #5BC0BE!important;
            }
            &.is-inRange{
              background: #3A506B!important;
            }
          }
        }
      }

    }
    .btn-primary{
      background: #5BC0BE ;
      padding:8px 15px;
      margin: 16px 0 0;
    }
  }
}

.Select.is-focused > .Select-control {
  border: 2px solid $secondaryColor !important;
  border-color: $secondaryColor !important;
  outline: 0;
  box-shadow: none !important;
}
.modalsub__btn {
  .update__town__btn {
    padding: 12px 20px;
    width: 100%;
    font-family: 'Pier Sans' !important;
    font-weight: bold;
    font-size: 14px;
    color: #FFFFFF;
    letter-spacing: 0.75px;
    text-align: center;
    background: $secondaryColor;
    outline: none;
    border: 0;
    border-radius: 3px;
    margin: 0;
  }
}
.labelcapital__global {
  text-transform: uppercase;
}
.labelinputs__global {
  width: 100%;
  font-weight: 600;
  font-size: 14px;
  color: $primaryColor;
  margin: 0 0 5px 0;
}
.textinputs__global {
  width: 100%;
  padding: 5px 15px;
  border: 2px solid #e5e5e5;
  background: #fff;
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px !important;
  height: 54px !important;
  border-radius: 4px;
  &:focus {
    border: 2px solid $secondaryColor;
    outline: 0;
  }
  #flagInputControlMainPhone {
    // margin-left: 40px !important;
    // width: 84% !important;
    // height: 50px;
    // border: 0;
    // outline: 0;
    // position: relative;
    // top: -5px;
    // right: -15px;
    margin-left: 0px !important;
    width: 100% !important;
    height: 50px !important;
    border: 0;
    outline: 0;
    position: relative;
    top: -5px;
    right: 0px;
    padding-left: 50px;
    left: 0;
    &:focus {
      border: 2px solid $secondaryColor;
      outline: 0;
      height: 54px !important;
      top: -7px;
      left: -2px;
      right: -2px;
      width: calc(100% + 4px) !important;
      border-radius: 4px;
    }
  }
  #flag-dropdown {
    background: none !important;
    border: 0 !important;
    border-bottom: 0px solid #9b9b9b;
    height: 40px !important;
  }
  .flagInputControlMainPhone {
    // margin-left: 40px !important;
    // width: 84% !important;
    // height: 50px;
    // border: 0;
    // outline: 0;
    // position: relative;
    // top: -5px;
    // right: -15px;
    margin-left: 0px !important;
    width: 100% !important;
    height: 50px;
    border: 0;
    outline: 0;
    position: relative;
    top: -5px;
    right: 0px;
    padding-left: 50px;
    left: 0;
    &:focus {
      border: 2px solid $secondaryColor;
      outline: 0;
      height: 54px;
      top: -7px;
      left: -2px;
      right: -2px;
      width: calc(100% + 4px) !important;
      border-radius: 4px;
    }
  }
}

.is-focused.sel__review {
  border: 2px solid $secondaryColor !important;
  outline: 0;
  border-radius: 4px;
}
.sel__review {
  &:focus {
    outline: 0;
  }
  .Select-control {
    padding: 0 !important;
    height: 54px;
    border: 2px solid #e5e5e5 !important;
    cursor: pointer;

    .Select-placeholder {
      text-align: left;
      height: 54px;
      line-height: 54px !important;
      padding: 0 15px;
      font-size: 14px;
    }
    .Select-input {
      margin-left: 0;
      padding: 0;
      width: 100%;
    }
    .Select-arrow-zone {
      .Select-arrow {
        position: relative;
        &::after {
          content: '';
          position: absolute;
          right: -7px;
          top: -10px;
          opacity: 100;
          width: 20px;
          height: 20px;
          background:#fff url('/img/custom-select-arrow.svg');
          background-size: 12px;
          background-repeat:no-repeat;
          background-position: center center;
        }
      }
    }
    .Select-multi-value-wrapper {
      width: calc(100% + 26px);
      height: 54px;
      padding: 0 15px;
      margin-left: -15px;
      // input {
      //   width: 100% !important;
      //   padding: 0px 0px;
      //   border: 2px solid #e5e5e5;
      //   background: #fff;
      //   color: rgba(0, 0, 0, 0.87);
      //   font-size: 14px;
      //   height: 50px;
      //   border-radius: 4px;
      //   line-height: 50px;
      //   font-size: 14px;
      //   background:#fff url('/img/custom-select-arrow.svg');
      //   background-size: 12px;
      //   background-repeat:no-repeat;
      //   background-position: calc(100% - 15px);
      //   appearance: none;
      //   -moz-appearance: none;
      //   -o-appearance: none;
      //   -webkit-appearance: none;
      //   -ms-appearance: none;
      //   cursor:pointer;
      //   &:focus {
      //     border: 2px solid $secondaryColor;
      //     outline: 0;
      //   }
      // }
    }
  }
}
.textareainputs__global {
  width: 100%;
  border: 2px solid #e5e5e5 !important;
  background: #fff;
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  border-radius: 4px;
  resize: none;
  line-height: 16px;
  padding: 16px 15px !important;
  height: 160px !important;
  &:focus {
    border: 2px solid $secondaryColor !important;
    outline: 0;
  }
}
.selectinputs__global {
  width: 100% !important;
  border: 2px solid #e5e5e5 !important;
  background: #fff;
  color: rgba(0, 0, 0, 0.87);
  height: 54px !important;
  border-radius: 4px;
  resize: none;
  line-height: 19px !important;
  padding: 16px 15px;
  font-size: 14px !important;
  background:#fff url('/img/custom-select-arrow.svg');
  background-size: 12px;
  background-repeat:no-repeat;
  background-position: calc(100% - 15px);
  appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  cursor:pointer;
  &:focus {
    border: 2px solid $secondaryColor !important;
    outline: 0;
  }
}
.react-datepicker__input-container {
  &:focus {
    border: 2px solid $secondaryColor;
    outline: 0;
  }
}
.selectdate__global {
  width: 100%;
  border: 2px solid #e5e5e5 !important;
  background: #fff !important;
  color: rgba(0, 0, 0, 0.87) !important;
  text-align: left !important;
  height: 54px;
  border-radius: 4px !important;
  resize: none;
  line-height: 19px;
  padding: 16px 15px 16px 70px !important;
  font-size: 14px !important;
  background-image: url('/img/custom-select-arrow.svg'), url("/img/calendar-icon.svg") !important;
  background-position: calc(100% - 15px), 19px center !important;
  background-repeat: no-repeat, no-repeat !important;
  background-size: 12px, 30px !important;
  appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  cursor:pointer;
  &:focus {
    border: 2px solid $secondaryColor !important;
    outline: 0;
  }
}

.selecttime__global {
  width: 100%;
  border: 2px solid #e5e5e5 !important;
  background: #fff !important;
  color: rgba(0, 0, 0, 0.87) !important;
  text-align: left !important;
  height: 54px;
  border-radius: 4px !important;
  resize: none;
  line-height: 19px;
  // padding: 16px 15px 16px 70px !important;
  padding: 6px 15px 6px 70px !important;
  font-size: 14px !important;
  background-image: url('/img/custom-select-arrow.svg'), url("/img/time_img.svg") !important;
  background-position: calc(100% - 15px), 19px center !important;
  background-repeat: no-repeat, no-repeat !important;
  background-size: 12px, 30px !important;
  appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  cursor:pointer;
  &:focus {
    border: 2px solid $secondaryColor;
    outline: 0;
  }
}




.search-placebox--hr {
  hr {
    border-bottom: 1px solid #E6EBF0 !important;
  }
  input {
    &:focus {
      border-bottom: 2px solid $secondaryColor !important;
      bottom: 8px !important;
    }
  }
}
.padd__t--b {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}
.overflow_visible {
  overflow: visible !important;
}
.open__sans--font {
  font-family: 'Pier Sans' !important;
}
.open__sans--boldfont {
  font-weight: bold !important;
}
.no__img {
  min-width: 40px !important;
  min-height: 40px !important;
  border-radius: 50% !important;
  background: rgb(199, 206, 74) !important;
  color: #fff !important;
}
.show__hori--scroll {
  overflow-x: auto;
}
.navigation__links.btn__grey.client_sms--btn {
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  color: #fff;
  background: $secondaryColor;
  border-radius: 4px;
  min-width: 128px;
  padding: 0px 15px;
  margin-right: 10px;
  float: right;
  height: 40px;
  line-height: 40px;
  text-transform: uppercase !important;
}
.client__activity--info--items {
  h1 {
    font-size: 13px !important;
  }
}
.font__size--update > tbody > tr > td {
  font-size: 13px !important;
}
.styles_modal__gNwvD {
  max-height: 550px;
  overflow-y: auto;
  width: 445px;
}
.p_l--rem {
  padding-left: 0;
}
.strategist_top{
  padding: 10px 25px;
  z-index: 9;
  font-weight: 600;
  font-size: 14px;
  @media #{$phone}{
    font-size: 13px !important;
    padding: 12px 25px;
  }
}


.strategist_top + div > div > div > div .navbar__header.rovtop {

  @media #{$tablet768}{
    top:40px !important;
  }
  @media #{$phone}{
    top:60px !important;
  }

}
.strategist_top + div > div > div > div .navbar__header {
  top:38px !important;
}
.strategist_top + div + div div + div div .close__top {
  margin-top: 0px !important;
  .agentclient__wrapper--padd {
    padding-top: 30px !important;
  }
}
.strategist_top + div + div + div div + div div div .padding-left {
  padding-top: 30px !important;
}
.secondary__navbar__header.header-client.auth_secondary__navbar__header {
  top: 40px!important;
}

.custom-select--all {
  width: 260px;
  max-width: 250px;
  height: 54px;
  line-height: 54px;
  padding: 0 20px;
  border: 0;
  border-right: 1px solid #E6EBF0;
  font-size: 15px;
  font-weight: 600;
  color: #787878;
  letter-spacing: 0.44px;
  border-radius: 0;
  background-color: #fff;
  background-image: url(/img/custom-select-arrow.svg);
  background-size: 14px;
  background-repeat: no-repeat;
  background-position: calc(100% - 15px) center;
  appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  cursor: pointer;
  position: relative;
  @media #{$phone}{
    margin-bottom: 5px;
    height: 55px;
    line-height: 55px;
    width: 100%;
    max-width: 100%;
    border: 1px solid #E6EBF0;
  }
}
.singleLine {
  white-space: pre;
}

// body::-webkit-scrollbar {
//   width: 1em;
// }

// body::-webkit-scrollbar-track {
//   -webkit-box-shadow: inset 0 0 6px $secondaryColor;
// }

// body::-webkit-scrollbar-thumb {
// background-color:  $secondaryColor;
// outline: 1px solid  $secondaryColor;
// }
// Bootrsrap Overwrite CSS
.left_bord::after {
  content:"";
  border-right: 1px solid #E5E5E5;
  position: absolute;
  bottom: 0;
  top: 0;
  height: 100%;
}
.right_bord::after {
  content: "";
  border-right: 1px solid #E5E5E5;
  position: absolute;
  bottom: 0;
  top: 0;
  height: 100%;
  right: -16px;
}
.outer-input-forgot {
  float: left;
  width: 100% !important;
  margin-top: 35px !important;
}

.dashboard-footer {
  background-color: rgba(236, 237, 232, 0.4);
  padding: 8px 0;
  float: left;
  text-align: center;
  width: 100%;
  font-size: 14px;
  margin-top: 70px;
}
.clearfilterTab {
  display: flex;
  align-items: center;
  height: 54px;
}
.clear-filter {
  margin: 0 0 0 20px !important;
  font-size: 15px;
  font-weight: 600;
  color: $secondaryColor;
  cursor: pointer;
  float: left;
}

.styles__searchContainer___3zLYs:focus + span ~ label {
  top: -15px !important;
  font-size: 13px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.54);
  line-height: 16px;
}
.overDueRed{
  color:#D0021B !important;
}

.client-review-select.custom-select {
  padding: 0 10px !important;
  min-width: 160px;
  margin-left: -10px;
}


/*calendar open*/
.react-datepicker__month-dropdown {
  .react-datepicker__month-option {
    color: $primaryColor;
  }
}
.react-datepicker__month-read-view--down-arrow {
  border-top-color: #fff !important;
}
.react-datepicker__year-read-view--down-arrow {
  border-top-color: #fff !important;
}
.react-datepicker-popper {
  width: 300px;
  z-index: 9999999 !important;
  .react-datepicker {
    float: left;
    width: 300px;
  }
  .react-datepicker__month-container {
    float: left;
    width: 300px;
  }
  .react-datepicker__header {
    background-color: $secondaryColor;
    border-bottom: 1px solid $secondaryColor;
    font-size: 12px;
    font-weight: 700;
    color: #fff!important;
  }
  .react-datepicker__current-month {
    color: #fff;
    font-size: 1.644rem;
  }
  .react-datepicker__day--disabled {
    color: #ccc !important;
  }
  .react-datepicker__month .react-datepicker__day {
    margin: 6px 9px !important;
    font-size: 12px;
    font-weight: 400!important;
    color: $primaryColor;
  }
  .react-datepicker__day-name {
    margin: .966rem;
  }
  .react-datepicker__day--selected {
    width: 22px;
    height: 22px;
    background: $secondaryColor;
    line-height: 22px;
    text-align: center;
  }
  .react-datepicker__navigation--next {
    right: 10px;
    border-left-color: #fff;
  }
  .react-datepicker__navigation--previous {
    left: 10px;
    border-right-color: #fff;
  }
  .react-datepicker__day--selected {
    color: #fff !important;
  }
}
/*calendar close*/
.react-calendar__tile.react-calendar__tile--now.react-calendar__tile--active {
  /*color: #fff !important;*/
}
.react-calendar__tile.react-calendar__tile--active.react-calendar__tile--rangeStart {
  /*color: #fff !important;*/
}
select {
  outline: none !important;
}
.table {
  /*min-width: 1440px;*/
  max-width: 100%;
  width: 100%;
  margin-bottom: 20px;
}
.react-datepicker-popper {
  .react-datepicker__day-name {
    margin: 6px 9px !important;
    color: $white !important;
  }
  .react-datepicker__month-container {
    width: 298px !important;
  }
}
.strategist-search {
  input {
    box-sizing: border-box;
    font-family: 'Poppins';
    font-size: 14px;
    background-color: white;
    background-image: url("/img/search-icon.svg");
    background-position: 16px 17px;
    background-repeat: no-repeat;
    padding: 13px 20px 13px 50px !important;
    border-radius: 4px;
    height: 54px;
    max-width: 100%;
  }
}

.totalRecords {
  color: $secondaryColor;
  padding-top: 14px;
  display: inherit;
}
.custom-sel-filter-new {
  height: 55px !important;
  line-height: 55px !important;
  top: 2px;
}
.custom-sel-filter {
  border: 2px solid #e5e5e5;
  width: 260px;
  max-width: 250px !important;
  height: 55px;
  border-radius: 4px;
  background-color: #fff;
  font-size: 14px;
  font-weight: 600;
  color: $primaryColor;
  line-height: 19px;
  padding: 0 40px 0 20px !important;
  -webkit-appearance: none;
  background-image: url('/img/custom-select-arrow.svg');
  background-size: 14px;
  background-repeat: no-repeat;
  background-position: calc(100% - 15px) center;
  appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  cursor: pointer;
  position: relative;
  padding-left: 10px;
  text-align: left !important;
  &:focus {
    border: 2px solid $secondaryColor !important;
    outline: 0;
  }
  @media #{$tablet}{
    width: 212px !important;
    max-width: 212px !important;
  }
  @media #{$phone}{
    width: 26% !important;
    max-width: 100% !important;
    margin-bottom: 7px;
    border-radius: 4px;
  }
}
.rem-left {
  margin-left: -15px;
}
.rem-right {
  margin-right: -15px;
}
.sel-outer {
  width: 250px;
  max-height: 200px;
  overflow-y: auto;
  box-shadow: 2px 5px 10px #c5c5c5;
  position: absolute;
  background: #fff;
  z-index: 99;
  top: 80px;
  overflow-x: hidden;
  @media #{$phone}{
    top: 60px;
  }
  li {
    width: 100% !important;
    max-width: 100% !important;
    float: left;
  }
}
.sel-outer-edit {
  top: 56px !important;
  @media #{$tablet980}{
    width: calc(100% - 20px) !important;
  }
  @media #{$tablet768}{
    top: 60px !important;
    width: 100% !important;
    overflow-x: hidden;
  }
}
.custom-select-option {
  //padding: 5px 15px;
  padding: 5px 5px !important;
}
.rbc-toolbar{
  left: 250px;
  right: 0;
  button {
    min-width: 60px;
  }
  .rbc-toolbar-label {
    position: absolute;
    left: 260px;
  }
}
.rbc-btn-group:first-child {
  left:250px;
}
.rbc-btn-group:last-child {
  position: absolute;
  right: -20px;
}
.calendar__page--container{
  .calendar__page--small-calendar{
    display: none;
  }
  .calendar__page--big-calendar{
    width: 100% !important;
  }
}

.newfilter-list {
  right: -15px;
  position: relative;
  @media #{$tablet}{
    right: -15px;
  }
  @media #{$phone}{
    right: 0;
    padding: 0;
    margin-top: 25px;
  }
  li {
    float: right;
    margin-left: 15px;
    margin-bottom: 5px;
    text-align: left;
    @media #{$phone}{
      width: 100%;
      margin-left: 0;
    }
    .custom-sel-filter {
      @media #{$phone}{
        width: 100% !important;
        margin-top: 10px;
        height: 46px !important;
        line-height: 46px !important;
        background-position: calc(100% - 15px) center !important;
      }
    }
  }
}

.filter__container--button {
  margin-bottom: 5px;
}
.rem-flex {
  display: unset !important;
  margin-bottom: 20px;
  float: left;
  width: 100%;
}
.filter__sub--container-edit {
  float: left !important;
  margin-top: 15px !important;
  @media #{$phone}{
    width: 100% !important;
    margin-bottom: 0 !important;
  }
  li {
    @media #{$phone}{
      width: 100% !important;
      margin-bottom: 5px;
    }
    .custom-select {
      background-position: calc(100% - 15px) !important;
      @media #{$phone}{
        width: 100% !important;
        height: 52px;
        line-height: 52px;
        border: 1px solid #E5ECED;
      }
    }
  }
}
.filter__container--filter-name {
  .enteries-span {
    select {
      border: 2px solid #e5e5e5;
      border-radius: 4px;
      background-color: #fff;
      font-size: 14px;
      font-weight: 600;
      color: $primaryColor;
      line-height: 19px;
      padding: 12px 40px 12px 18px;
      margin-left: 5px;
      -webkit-appearance: none;
      overflow: hidden;
      background-image: url("/img/custom-select-arrow.svg");
      background-size: 14px;
      background-repeat: no-repeat;
      background-position: calc(100% - 15px) center;
      appearance: none;
      -moz-appearance: none;
      -o-appearance: none;
      -webkit-appearance: none;
      -ms-appearance: none;
      cursor: pointer;
      &:focus {
        border: 2px solid $secondaryColor;
        outline: 0;
      }
    }
  }
  .enteries-label {
    font-size: 13px;
    font-weight: 500;
    color: #787878;
  }
  select {
    border: 1px solid #d6d6d6;
    border-radius: 4px;
    background-color: #fff;
    font-size: 14px;
    font-weight: 600;
    color: $primaryColor;
    line-height: 19px;
    padding: 7px 40px 7px 10px;
    margin-left: 5px;
    -webkit-appearance: none;
    overflow: hidden;
    background-image: url("/img/custom-select-arrow.svg");
    background-position: calc(100% - 15px) center;
    background-size: 14px;
    background-repeat: no-repeat;
    appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    -webkit-appearance: none;
    -ms-appearance: none;
    cursor: pointer;
  }
}


.client-review-select {
  background-color: transparent !important;
  border: 0 !important;
  padding: 2px 30px 2px 0 !important;
  color: #C6CE4A !important;
  outline: none;
  background-image: url("/img/profile-arrow.svg") !important;
  font-weight: 400 !important;
}
.multiplesel-edit::after{
  border-bottom: none !important;
}
.MuiSelect-select-2:focus {
  background: none !important;
}
.multiplesel-edit {
  color: #73777A !important;
  font-size: 14px !important;
  min-width: 140px !important;
  &:before {
    content: "";
    border-bottom: 0px solid #ccc !important;
  }
  &:focus {
    border-bottom: 0px solid #ccc !important;
    background-color: transparent !important;
    &:after {
      content: "";
      border-bottom: 0px solid #ccc !important;
    }
  }

  svg {
    /*background: url('/img/custom-select-arrow.svg');*/
    background-image: url("/img/profile-arrow.svg") !important;
    background-size: 12px;
    background-repeat: no-repeat;
    top: 10px;
    overflow: hidden;
    width: 15px;
    height: 10px;

  }
}
// svg {
//   color: $secondaryColor !important;
// }
.no-strategy-msg {
  text-align: center;
  font-size: 14px;
  margin: 20px 0 50px 0;
}
.agent-town-lists {
  li {
    padding: 0 !important;
    @media #{$phone}{
      padding: 14px !important;
    }
    .select__agent--name {
      padding: 24px !important;
      @media #{$phone}{
        padding: 15px !important;
      }
    }
  }
  .form-control {
    padding: 3px 10px !important;
  }
}
.strategy--form__btn {
  .btn__green--lg {
    @media #{$phone}{
      min-width: 130px !important;
    }
  }
  .btn__black--lg {
    @media #{$phone}{
      min-width: 130px !important;
    }
  }
}
.time-listing:focus {
  outline: none !important;
  border: none;
}
.strategist-yesno {
  padding-bottom: 25px;
  margin-top: -15px;
}
.strategist-yesno .switch {
  margin: 0 10px;
}
.timezone_error_msg {
  color: red;
  display: block;
  float: left;
  width: 100%;
  margin-top: -10px;
}
.welcome_agent_main {
  position: relative;
  width: 100%;
  float: left;
  text-align: center;
  height: 90vh;
  .agent__page--head {
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    @media #{$phone}{
      max-width: 100%;
      margin: 0px auto;
      text-align: center;
      width: 90%;
    }
    p {
      font-size: 18px;
      color: #9b9b9b;
      margin-bottom: 25px;
    }
    h1 {
      margin-bottom: 25px;
    }
  }
}
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px white inset !important;
  -moz-box-shadow: 0 0 0 1000px white inset !important;
}
.forcursor {
  cursor: pointer;
}
.uses-list tr {
  cursor: pointer;
}
.noEmailRecords {
  padding: 25px;
}
.upcoming__appointments--table-list tr {
  cursor: pointer;
}
.ForgotSection {
  background: #fff;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  width: 55%;
  margin-top: 20%;
  padding: 30px 40px;
  @media #{$tablet}{
    width: 85%;
    margin: 0 auto;
    transform: none !important;
    position: static !important;
    margin-top: 10%;
  }
  @media #{$phone}{
    width: 100%;
    float: left;
    margin-top: 10%;
    transform: none !important;
    position: static !important;
    padding: 20px 20px;
  }
  h3 {
    font-size: 24px;
    font-family: "Dosis-SemiBold";
    margin: 0 0 25px 0;
  }
  p {
    margin-bottom: 50px;
  }
  input {
    border: 0;
    border-bottom: 1px solid #9B9B9B;
    width: 100%;
    height: 30px;
    color: $primaryColor;
    font-size: 16px;
    border-radius: 0;
    font-family: 'Dosis-Regular';
    outline: none;
    padding: 0px 0 0px !important;
    background: transparent;
    margin-bottom: 35px;
    &:focus {
      border-bottom: 1px solid $secondaryColor;
    }
  }
  input:focus + label {
    color: $secondaryColor;
    font-size: 14px;
    font-family: "Dosis-Medium";
    font-weight: 600;
  }
  button {
    padding: 0;
    text-align: center;
    background: $secondaryColor;
    border: 2px solid $secondaryColor !important;
    color: #fff;
    font-size: 18px;
    border-radius: 0;
    font-family: 'Dosis-Bold';
    outline: none;
    box-shadow: none;
    border: none;
    padding: 10px 45px;
    border-radius: 4px;
  }
  .for-right {
    float: right;
    a {
      color: #333;
      text-decoration: none !important;
    }
  }
  label {
    width: 100%;
    color: #9B9B9B;
    font-size: 14px;
    font-family: "Dosis-Medium";
    position: absolute;
    top: -20px !important;
    left: 0;
    line-height: 17px !important;
  }
}
.navbar-default {
  background-color: $grey__Color;
  border-color: $grey__Color;
  border-radius:0;
  margin-bottom:0;
}
.navbar {
  padding: 6px 0;
}
.navbar-default .navbar-nav>.open>a, .navbar-default .navbar-nav>.open>a:focus, .navbar-default .navbar-nav>.open>a:hover {
  color: #555;
  background-color: $grey__Color;
}
.navbar-default .navbar-toggle:focus, .navbar-default .navbar-toggle:hover {
  background-color: transparent;
}
.navbar-default .navbar-toggle {
  border-color: transparent;
}

.navbar__header--hamburgar{
  .navbar-toggle {
    margin-right: 0;
  }
}

.navbar-toggle .icon-bar {
  width: 27px;
  height: 2px;
  border-radius: 2px;
  background: $secondaryColor;
  margin: 8px 0;
}
.navbar-default .navbar-toggle .icon-bar {
  background-color: $secondaryColor;
}
.dropdown-menu>li>a:focus, .dropdown-menu>li>a:hover {
  background-color: $white__Color;
}
.form-control {
  padding: 6px 16px;
  font-size: 16px;
  color: $primaryColor;
  border-radius: 4px;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid #CED0DA;
}
.form-control:focus {
  border-color: #B5C9D4;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
// .has-error .form-control {
//     border-color: #a94442;
//     -webkit-box-shadow: none;
//     box-shadow: none;
// }
// .has-error .form-control:focus {
//     border-color: transparent;
//     -webkit-box-shadow: none;
//     box-shadow: none;
// }
.btn {
  padding: 9px 12px;
  font-size: 13px;
  line-height: 19px;
  font-weight: 600;
  border: 0px solid transparent;
  border-radius: 6px;
  &:focus,
  &:active {
    outline: none !important;
    box-shadow: none;
  }
}
.btn-link:focus, .btn-link:hover {
  color: $secondaryColor;
  text-decoration: none;
  background-color: transparent;
  outline: none !important;
}
.table-responsive{
  margin-bottom: 0;
  border: 0px solid #ddd;
}

.modal:after{
  content: "";
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
  background-color: $primaryColor;
  opacity:0.5;
}
.modal-header {
  padding: 17px 39px;
  border-bottom: 1px solid #E6EBF0;
  text-align: center;
}
.modal-title {
  font-size: 20px;
  font-weight:600;
  color: #73777A;
  line-height: 27px;
}
.modal-body {
  padding: 30px 39px 30px;
}
.modal {
  text-align: center;
}
.modal::before {
  content: "";
  display: inline-block;
  height: 100%;
  margin-right: -4px;
  vertical-align: middle;
}
.modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
  z-index: 1043;
}

.btn__transparent{
  background: transparent;
  padding: 0;
  &:hover,&:focus,&:active{
    outline:none !important;
    box-shadow:none;
  }
}

.btn__green--lg,
.btn__black--lg{
  font-size: $btn__font--size;
  font-weight: 600;
  min-width: $btn__width;
  padding-top: $btn__p--top;
  padding-bottom: $btn__p--bottom;
  border-radius: 6px;
}

.btn__green{
  color: $white__Color;
  background-color: $secondaryColor;
  border-color: $secondaryColor;
  &:hover,&:focus{
    color: $white__Color;
    background-color: #12284A;
    border-color:  #12284A;
    outline:none;
    box-shadow:none;
  }
}
.btn__red{
  color: $white__Color;
  background-color: $red__Color;
  border-color: $red__Color;
  &:hover,&:focus{
    color: $white__Color;
    background-color: $red__Color;
    border-color:  $red__Color;
    outline:none;
    box-shadow:none;
  }
}
.btn__red--outline{
  color: $red__Color;
  background-color: transparent;
  border:1px solid $red__Color;
  &:hover,&:focus{
    color: $white__Color;
    background-color: $red__Color;
    border-color:  $red__Color;
    outline:none;
    box-shadow:none;
  }
}
.btn__green--outline{
  color: $secondaryColor;
  background-color: transparent;
  border:1px solid $secondaryColor;
  &:hover,&:focus{
    color: $white__Color;
    background-color: #12284A;
    border-color:  #12284A;
    outline:none;
    box-shadow:none;
  }
}

.btn__black{
  color: $white__Color;
  background-color: $primaryColor;
  border-color: $primaryColor;
  &:hover,&:focus{
    color: $white__Color;
    background-color: #12284A;
    border-color:  #12284A;
    outline:none;
    box-shadow:none;
  }
}

.btn__black--outline{
  color: $primaryColor;
  background-color: transparent;
  border:1px solid $primaryColor;
  padding: 8px 12px;
  &:hover,&:focus{
    color: $white__Color;
    background-color:$primaryColor;
    border-color:  $white__Color;
    outline:none;
    box-shadow:none;
  }
}

.btn__delete--outline{
  color: #D0021B;
  background-color: transparent;
  border:1px solid #D0021B;
  padding: 8px 12px;
  &:hover,&:focus{
    color: $white__Color;
    background-color:#D0021B;
    border-color:  #D0021B;
    outline:none;
    box-shadow:none;
  }
}

.btn__grey{
  color: $white__Color;
  background-color: #CED0DA !important;
  border-color: #CED0DA;
  &:hover,&:focus{
    color: $white__Color;
    background-color: #CED0DA;
    border-color:  #CED0DA;
    outline:none;
    box-shadow:none;
  }
}


.btn-green-link,
.btn-green-link.active,
.btn-green-link:active, .btn-green-link[disabled], fieldset[disabled] .btn-green-link {
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-green-link {
  color: $secondaryColor;
  border-radius: 0;
  &:hover{
    color: $secondaryColor;
    text-decoration:none;
  }
  &:focus,&:active{
    color: $secondaryColor;
    text-decoration:none;
    outline:none;
  }
}
.form-control.additional__textarea {
  border-radius: 0 !important;
  height: 42px;
}

// Bootrsrap Overwrite CSS

.text__label--sm{
  font-size: 13px;
  font-weight: 400;
  color: rgba(0,0,0,0.54);
  text-align: left;
  line-height: 16px;
  margin: 0 0 10px;
}

.text__label--smnew{
  font-size: 12px;
  font-weight: 400;
  color: $primaryColor;
  text-align: left;
  line-height: 18px;
  margin: 0 0 5px;
  text-transform: uppercase;
}

// Custom Style
.p__l--0{
  padding-left: 0;
  @media #{$phone6,$phone6Plus}{
    padding-left: 15px;
  }
}
.p__r--0{
  padding-right: 0;
  @media #{$phone6,$phone6Plus}{
    padding-right: 15px;
  }
}

.m__l--auto{
  margin-left: auto;
}

.b__top{
  border-top: 1px solid #e5e5e5;
}
.b__right{
  border-right: 1px solid #e5e5e5;
}
.b__bottom{
  border-bottom: 1px solid #e5e5e5;
}
.b__left{
  border-left: 1px solid #e5e5e5;
}
// Custom Style

// Secondry navigation
.secondary__navbar__header{
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-align: center;
  align-items: center;
  height: 84px;
  background-color: $white__Color;
  margin: 0 auto;
  padding: 0 15px;
  // position: fixed;
  // top: 0;
  // left: 0;
  // right: 0;
  // z-index: 1025;
  z-index: 9;
}
.secondary__navbar__header--logo{
  margin-right: 30px;
  font-size: 17px;
  font-weight: 600;
  color: #73777A;
  // width: 100%;
  @media #{$phone6}{
    margin-right: 15px;
  }
  &:hover,&:focus{
    color: #73777A !important;
    text-decoration: none;
    outline: none;
  }
  span{
    text-transform: capitalize;
    @media #{$phone,$tablet}{
      display: none;
    }
  }
  img{
    width: 55px;
    height: 55px;
    border-radius: 100px;
    object-fit: cover;
    margin-right: 10px;
  }
}
.secondary__navbar__header--desktop{
  display: -ms-flexbox;
  display: flex;
  // width: 100%;
}
.secondary__navbar__header--form{
  margin: auto 0;
  width: 100%;
  position: relative;
}
.secondary__navbar__header--search-wrapper{

}
.secondary__navbar__header--navigation{
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  margin-left: 18px;
  .avtar__dropdown{
    @media #{$tablet}{
      margin-right: 33px;
    }
    @media #{$tabletLandscape,$iPadPro}{
      margin-right: 0;
    }

  }


  .costom-dropdown{
    border-left: 0px solid #E5E5E5;
    padding-left: 0;
  }
}
.secondary__navigation__links{
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  color: $white__Color;
  background: $secondaryColor;
  border-radius: 4px;
  min-width: 128px;
  padding: 12px 6px;
  margin-right: 10px;
  &:hover,&:focus{
    background:#cfd651;
    text-decoration: none;
    color: $white__Color;
  }
}
// Secondry navigation


// Color Classes
.text-white{
  color: $white__Color;
}
.text-red{
  color: $red__Color !important;
}
.text-orange{
  color: #FBBC05;
}
.text-green{
  color:$grey__Color;
}
.text-black{
  color: $primaryColor;
}
.text-muted {
  color: #979797;
}
// Color Classes

// Header Css
.navbar__header{
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-align: center;
  align-items: center;
  height: 84px;
  background-color: $grey__Color;
  margin: 0 auto;
  padding: 0 15px;
  position: fixed;
  top: 0;
  left: 117px;
  right: 0;
  z-index: 9;
  // z-index: 99999;
}
.navbar__header--logo{
  margin-right: 30px;
  margin-left: -15px;
  width: 117px;
  @media #{$phone6,$phone6Plus}{
    // margin-right: 15px;
  }
  img{
    height: 84px;
    width: 117px;
  }
}
.navbar__header--desktop{
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}
.navbar__header--form{
  margin: auto 0;
  width: 100%;
  position: relative;
}
.navbar__header--search-wrapper{

}
.navbar__header--navigation{
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  margin-left: 18px;
  @media #{$tablet980}{
    margin-left: 10px;
  }
  @media #{$phone6,$phone6Plus}{
    margin-left: auto;
  }
}
.navigation__links{
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  color: $white__Color;
  background: $secondaryColor;
  border-radius: 4px;
  min-width: 150px;
  padding: 12px 6px;
  margin-right: 10px;
  float: left;
  border: none;
  &:hover,&:focus{
    background:#cfd651;
    text-decoration: none;
    color: $white__Color;
  }
}
// Header Css

// Input Styles
.text-group{
  position:relative;
  margin-bottom: 32px;
  .form-control {
    padding: 6px 0;
    font-size: 16px;
    color: $primaryColor;
    border: 0px solid #E6EBF0;
    border-bottom:1px solid #E6EBF0;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .form-control:focus {
    border-color: transparent;
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .has-error .form-control {
    border-color: #a94442;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .has-error .form-control:focus {
    border-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

}
.text-input:focus     { outline:none; }
.text-label{
  font-size: 15px;
  font-weight:500;
  color: rgba(0,0,0,0.87);
  line-height: 16px;
  margin:0;
  position:absolute;
  pointer-events:none;
  left:0;
  top:7px;
  transition:0.2s ease all;
  -moz-transition:0.2s ease all;
  -webkit-transition:0.2s ease all;
}
input:focus ~ label, input:valid ~ .text-label    {
  top:-15px;
  font-size: 13px;
  font-weight:400;
  color: rgba(0,0,0,0.54);
  line-height: 16px;
}
.bar  { position:relative; display:block; width:100%; }
.bar:before, .bar:after   {
  content:'';
  height:2px;
  width:0;
  bottom: 0px;
  position:absolute;
  background:$secondaryColor;
  transition:0.2s ease all;
  -moz-transition:0.2s ease all;
  -webkit-transition:0.2s ease all;
}
.bar:before {
  left:50%;
}
.bar:after {
  right:50%;
}
input:focus ~ .bar:before, input:focus ~ .bar:after {
  width:50%;
}

.bar_new  { position:relative; display:block; width:100%; }
.bar_new:before, .bar_new:after   {
  content:'';
  height:2px;
  width:0;
  top: 30px;
  position:absolute;
  background:$secondaryColor;
  transition:0.2s ease all;
  -moz-transition:0.2s ease all;
  -webkit-transition:0.2s ease all;
}
.bar_new:before {
  left:50%;
}
.bar_new:after {
  right:50%;
}
input:focus ~ .bar_new:before, input:focus ~ .bar_new:after {
  width:50%;
}

.testt {
  top: -15px;
  font-size: 13px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.54);
  line-height: 16px;
}
// Input Styles
.text-green{
  color:$secondaryColor;
}
.text-white{
  color:$white__Color;
}
.bg-green{
  background-color:$secondaryColor;
}
.form-title{
  font-size: 23px;
  font-weight:600;
  color: #73777A;
  line-height: 27px;
}

.agentEntry {
  margin-top: 20px;
  margin-bottom: 10px;
  text-align: right;
}

// new town reports css open
#sidebar-wrapper.town__reports--sidebar {
  width: auto;
  position: static;
  height: auto;
  margin: 44px 0 0 0;
  overflow: hidden;
  background: none;
  .sidebar-nav {
    top: 0;
    width: 100%;
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      display: inline-block;
      font-size: 13px;
      font-weight: 500;
      color: #787878;
      letter-spacing: 0.38px;
      text-align: center;
      line-height: normal;
      box-shadow: none;

      a {
        display: inline-block;
        color: #787878;
        background: none;
        padding: 9px 22px;
        border-radius: 100px;
        background: #F4F4F4;
        border: 2px solid #F4F4F4;
        line-height: normal;
        margin-right: 22px;
      }
      a.active {
        color: #C6CE4A;
        border: 2px solid #C6CE4A;
        background: #fff;
      }
    }
  }
}
.header__flex {
  display: flex;
  align-items: center;
  padding: 29px 0 0 0;
  span {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    color: #72777A;
    padding-left: 20px;
  }
  .btn__view--townreport {
    background: #C6CE4A;
    border-radius: 6px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: normal;
    text-align: center;
    color: #FFFFFF;
    padding: 10px 18px;
    margin-right: 47px;
  }
  .flex__right {
    width: calc(100% - 45%);
    float: right;
    text-align: right;
  }
}
.quickfacts__listing {
  .media-heading {
    font-size: 15px;
  }
  p {
    font-size: 15px;
  }
}

.quickfacts__row {
  padding-bottom: 35px;
  .section__title {
    font-size: 20px;
    margin-bottom: 8px;
    line-height: 30px;
    img {
      margin-left: 10px;
    }
  }
}
.select__common {
  border: 1px solid #d6d6d6;
  border-radius: 0;
  background-color: #fff;
  font-size: 14px;
  font-weight: 500;
  color: $primaryColor;
  line-height: 19px;
  padding: 16px 14px 16px 14px;
  margin-left: 0;
  -webkit-appearance: none;
  overflow: hidden;
  background-image: url(/img/custom-select-arrow.svg);
  background-size: 12px;
  background-repeat: no-repeat;
  background-position: calc(100% - 14px);
  background-position-y: 50%;
  appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  cursor: pointer;
  width: 100%;
}
.view__breakdown--edit {
  border: 1px solid #d6d6d6;
  border-radius: 4px;
  background-color: #fff;
  font-size: 14px;
  font-weight: 600;
  color: $primaryColor;
  line-height: 19px;
  padding: 10px 14px 10px 14px;
  margin-left: 5px;
  -webkit-appearance: none;
  overflow: hidden;
  background-image: url(/img/custom-select-arrow.svg);
  background-size: 12px;
  background-repeat: no-repeat;
  background-position: calc(100% - 14px);
  background-position-y: 65%;
  appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  cursor: pointer;
  margin-bottom: 15px;
  width: 65%;
  label {
    display: block;
    color: #9B9B9B;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 9px;
    line-height: 9px;
    margin-bottom: 1px;
  }
  select {
    outline: none !important;
    background: none;
    border: none;
    appearance: none;
    -o-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -webkit-appearance: none;
    font-family: Poppins;
    font-size: 13px;
    color: #9B9B9B;
    width: 100%;
    cursor: pointer;
  }
}
// new town reports css close

// SideBar navigation
#wrapper {
  padding-left: 0;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
#wrapper.toggled {
  padding-left: 117px;
}
.sidebar-wrapper--back {
  z-index: 1 !important;
}
#sidebar-wrapper {
  // z-index:1032;
  z-index:11;
  position: fixed;
  top:0;
  left: 117px;
  width: 0;
  height: 100%;
  margin-left: -117px;
  overflow-y: auto;
  background: $secondaryColor;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  overflow-x: hidden;
}
#sidebar-wrapper.agentSidebarWrapper {
  background: #CED0DA;
  .sidebar-nav > li > a {
    padding: 21px 0 13px;
    display: block;
    text-decoration: none;
    color: $white__Color;
    font-weight:600;
    font-size:13px;
    &:hover{
      text-decoration: none;
      // color: $secondaryColor;
      background: rgba(255,255,255,0.2);
    }
    &:active,&:focus{
      text-decoration: none;
    }
    img{
      display:block;
      margin: 0 auto;
      margin-bottom:7px;
    }
    .non-active{
      display:none;
    }
    &.active{
      color: #CED0DA;
      background: rgba(255,255,255,1);
      img.active{
        display:none;
      }
      img.non-active{
        display:block;
      }
    }
  }
}

.transparent__scroll::-webkit-scrollbar {
  width: 0.9em;
  height: 0.9em;
}
.transparent__scroll::-webkit-scrollbar-track {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: none;
}
.transparent__scroll::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: transparent;
  -webkit-box-shadow: none;
}
.transparent__scroll::-webkit-scrollbar-thumb:window-inactive {
  background: transparent;
}
.rem__border--right {
  border-right: 0 !important;
}




//  ::-webkit-scrollbar {
//     width: 0.9em;
//     height: 0.9em;
// }
//  ::-webkit-scrollbar-track {
//     -webkit-border-radius: 10px;
//     border-radius: 10px;
//      background: rgba(221, 221, 221, 0.8);
// }
//  ::-webkit-scrollbar-thumb {
//     -webkit-border-radius: 10px;
//     border-radius: 10px;
//     background: rgb(168, 175, 38);
//     -webkit-box-shadow: inset 0 0 6px rgb(168, 175, 38);;
// }
//  ::-webkit-scrollbar-thumb:window-inactive {
//     background: rgb(168, 175, 38);
// }

#wrapper.toggled #sidebar-wrapper {
  width: 117px;
}
#page-content-wrapper {
  width: 100%;
  // position: absolute;
  padding: 83px 0 0;
  @media #{$tablet768}{
    padding: 40px 0 0;
  }
  @media #{$phone767}{
    padding: 70px 0 0;
  }
}
#wrapper.toggled #page-content-wrapper {
  position: absolute;
  margin-right: -117px;
}
.sidebar-nav {
  top: 0;
  width: 117px;
  margin: 0;
  padding: 0;
  list-style: none;
  > li{
    text-align: center;
    display: block;
  }
}

a.sidebar-brand{
  padding: 0 !important;
  background: #162224;
  &:hover{
    color: $white__Color;
    background: #162224 !important;
  }
  img{
    margin: 0 !important;
    height: 84px;
    width: 100%;
  }
}
.sidebar-nav > li > a {
  padding: 21px 0 13px;
  display: block;
  text-decoration: none;
  color: $white__Color;
  font-weight:600;
  font-size:13px;
  &:hover{
    text-decoration: none;
    // color: $green__Color;
    background: rgba(255,255,255,0.2);
  }
  &:active,&:focus{
    text-decoration: none;
  }
  img{
    display:block;
    margin: 0 auto;
    margin-bottom:7px;
  }
  .non-active{
    display:none;
  }
  &.active{
    color: $secondaryColor;
    background: rgba(255,255,255,1);
    img.active{
      display:none;
    }
    img.non-active{
      display:block;
    }
  }
}

@media(min-width:768px) {
  #wrapper {
    padding-left: 117px;
    padding-bottom: 70px;
  }

  #wrapper.toggled {
    padding-left: 0;
  }

  #sidebar-wrapper {
    width: 117px;
  }

  #wrapper.toggled #sidebar-wrapper {
    width: 0;
  }

  #page-content-wrapper {
    // padding: 20px;
    // padding: 83px 0 0;
    position: relative;
  }

  #wrapper.toggled #page-content-wrapper {
    position: relative;
    margin-right: 0;
  }
}
// SideBar navigation

// TopNavBar
.clear-notificaton{
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
  z-index: 9;
}
.logo{
  padding: 0 15px;
}
.search-from{
  width: 40%;
}

// Search Css
.typeahead {
  /*position: relative;*/
}
.clear-txt{
  position: absolute;
  right: 10px;
  z-index: 10;
  top: -5px;
  .btn-clear {
    padding: 6px 10px;
    font-size: 1.4rem;
    font-weight: 400;
    border: 0px solid transparent;
    border-radius: 6px;
    min-width: inherit;
    background: transparent;
    color: #73777A;
  }
}
.typeahead-dropdown {
  // display: none;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  margin-top: 17px;
  transition-delay: 0.75s;
  transition: display 1s;
  box-shadow: 0 4px 13px 0px rgba(31, 31, 31, 0.1);
  border-radius: 6px;
  z-index: 1;
  overflow: hidden;
  &:hover {
    display: block;
  }
  &:focus {
    z-index: 101;
    display: block;
  }
  .typeahead-list{
    list-style: none;
    padding: 0;
    margin: 0;
    > li{
      > a{
        background: $white__Color;
        padding: 10px 15px;
        display: block;
        color: #73777A;
        &:hover{
          text-decoration: none;
          background: #F7F8F6;
        }
      }
      &:first-child {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }
  }
}

.typeahead-input {
  z-index: 1;
  position: relative;

  &:focus {
    z-index: 3;
    + .typeahead-dropdown {
      z-index: 101;
      display: block;
    }
  }
}

// Search Css
.costom-search {
  width: 100%;
  box-sizing: border-box;
  border: 0;
  font-family: 'Source Sans Pro';
  font-size: 14px;
  background-color: white;
  background-image: url(/img/search-icon.svg);
  background-position: 22px 17px;
  background-repeat: no-repeat;
  padding: 13px 20px 13px 61px;
  box-shadow: 0 2px 20px 5px rgba(31, 31, 31, 0.04);
  border-radius: 6px;
  &:focus{
    outline: none;
  }
}
.costom-search::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  font-size: 14px;
  font-weight:400;
  color: #E5E5E5;
}
.costom-search::-moz-placeholder { /* Firefox 19+ */
  font-size: 14px;
  font-weight:400;
  color: #E5E5E5;
}
.costom-search:-ms-input-placeholder { /* IE 10+ */
  font-size: 14px;
  font-weight:400;
  color: #E5E5E5;
}
.costom-search:-moz-placeholder { /* Firefox 18- */
  font-size: 14px;
  font-weight:400;
  color: #E5E5E5;
}
.form-action-btn{
  .btn {
    margin-right: 18px;
    &:last-child{
      margin-right: 0;
    }
  }
}
.notification{
  padding:0px 19px 0 5px;
  @media #{$tablet980}{
    padding: 0px 5px 0 0;
  }

  // Custom notification
  .dropdown-menu {
    top: 160%;
    left:-170px;
    min-width: 318px;
    max-width: 318px;
    max-height: 240px;
    overflow-y: auto;
    padding: 0;
    margin: 0;
    background-color: $grey__Color;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border: 0px solid #ccc;
    border: 0px solid rgba(0,0,0,.15);
    border-radius: 3px;
    -webkit-box-shadow: 0 2px 4px 0 rgba(0,0,0,0.50);
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.50);
    @media #{$phone6,$phone6Plus}{
      left: inherit;
      right: 0;
      min-width: 280px;
      max-width: 318px;
    }
    > li {
      border-bottom:1px solid #E5E5E5;
      &:last-child{
        border-bottom:0px solid #E5E5E5;
      }
      position: relative;
      .clear-notificaton{
        /*position: absolute;
        top: 50%;
        right:5px;
        transform: translateY(-50%);
        cursor: pointer;*/

        position: absolute;
        top: 5px;
        right: 5px;
        cursor: pointer;
      }
      > a{
        padding: 15px;
        padding-right: 30px;
        white-space: pre-line;
        font-size: 14px;
        color: #73777A;
        .notificationTime{
          font-weight:300;
        }
      }
    }
    &:before{
      bottom: 100%;
      left: 55%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: rgba(136, 183, 213, 0);
      border-bottom-color: $grey__Color;
      border-width: 17px 8px;
      margin-left: -30px;
    }
  }
  // Custom notification

}
.notificatonShow-icon{
  position: absolute;
  right: 18px;
  top: 0;
  width: 12px;
  height: 12px;
  background-color: #CE0319;
  border-radius: 100px;
  border: 2px solid $white__Color;
}
.costom-dropdown{
  border-left: 1px solid #E5E5E5;
  padding-left: 19px;
  padding-right: 35px;
  @media #{$tablet980}{
    padding-left: 5px;
    padding-right: 20px;
  }
  .dropdown-toggle{

    &:hover,&:focus{
      text-decoration: none;
    }
    img{
      vertical-align:top;
    }
    .avart__arrow--icon{
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
    }
  }
  .dropdown-menu{
    background: $grey__Color;
    padding:0;
    min-width: 216px;
    border: 0px solid #ccc;
    border: 0px solid rgba(0,0,0,.15);
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    top:148%;
    right: 0;
    > li {
      > a {
        padding: 0 25px;
        font-weight: 600;
        color: #73777A;
        font-size: 15px;
        border-top: 1px solid #E5E5E5;
        height: 46px;
        line-height: 46px;
      }
    }
  }
}
.profile-avtar{
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 100px;
  overflow: hidden;
  float: left;
  img{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.profile-name{
  font-size: 15px;
  font-weight:600;
  color: #73777A;
  margin:0 0 0 13px;
  min-width: 70px;
  float: right;
  @media #{$tablet980}{
    margin: 0 0 0 5px;
    font-size: 14px;
  }
}
.profile-email{
  font-size: 11px;
  color: #73777A;
  margin:0 0 0 13px;
  @media #{$tablet980}{
    margin: 0 0 0 5px;
  }
}


.sidebar__menu--container{
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  background: #fff;
  width: 100%;
  max-width: 300px;
  z-index: 1043;
  &.collapse{
    display: inherit;
    height: inherit !important;
    opacity: 0;
  }
  &.collapse.in{
    color: red;
    display: inherit;
    opacity: 1;
  }
}
// TopNavBar

// Switch css
.switch {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 34px;
  height: 14px;
  margin: 0;
  input {
    display: none;
  }
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
  &:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: -2px;
    bottom: -2px;
    background-color: #ccc;
    -webkit-transition: .3s;
    transition: .3s;
    box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.24);
  }
}

input {
  &:checked + .slider {
    &:before {
      background-color: $secondaryColor;
    }
    background-color: rgba(199, 206, 74, 0.5);
  }
  &:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  &:checked + .slider:before {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
  }
}

/* Rounded sliders */

.slider.round {
  border-radius: 34px;
  &:before {
    border-radius: 50%;
  }
}
// Switch css

// Checkbox Custom
.custom-checkbox {
  display: block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 0;
  cursor: pointer;
  font-size: 14px;
  line-height: 22px;
  height: 20px;
  font-weight: 400;
  color: #9B9B9B;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  input {
    // position: absolute;
    opacity: 0;
    // min-width: 200px;
    cursor: pointer;
  }
}
/* When the checkbox is checked, add a blue background */
.custom-checkbox input:checked ~ .checkmark {
  background-color: $secondaryColor;
  border: 1px solid $secondaryColor !important;
}
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.custom-checkbox {
  input:checked ~ .checkmark:after {
    display: block;
  }
  .checkmark:after {
    left: 5px;
    top: 0px;
    width: 6px;
    height: 12px;
    border: solid $white__Color;
    border-width: 0 1px 1px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}
// Checkbox Custom

.container-small{
  max-width:955px;
  margin:0 auto;
}
.view-btn{
  font-size: 15px;
  font-weight:600;
  color: $secondaryColor;
  text-decoration: underline;
}
.disabled{
  pointer-events: none;
  opacity: 0.2;
}
select.custom-select{
  border: 2px solid #e5e5e5;
  border-radius: 4px;
  background-color: $white__Color;
  font-size: 14px;
  font-weight:600;
  color: $primaryColor;
  line-height: 19px;
  padding: 7px 25px 7px 10px;
  -webkit-appearance:none;
  overflow: hidden;
  background-image:url(/img/custom-select-arrow.svg);
  background-size: 12px;
  background-repeat:no-repeat;
  background-position: calc(100% - 15px) center;
  appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  cursor:pointer;
  &:focus{
    border: 1px solid $primaryColor;
  }
}
select.custom-select-small{
  border: 1px solid $primaryColor;
  border-radius: 4px;
  background-color: $white__Color;
  font-size: 14px;
  font-weight:600;
  color: $primaryColor;
  line-height: 19px;
  padding: 7px 25px 7px 10px;
  -webkit-appearance:none;
  overflow: hidden;
  background-image:url(/img/custom-select-arrow.svg);
  background-size: 12px;
  background-repeat:no-repeat;
  background-position: 94%;
  cursor:pointer;
  &:focus{
    border: 1px solid $primaryColor;
  }
}

.coverage__area {
  .Select-control {
    border: 2px solid #e5e5e5;
    padding: 5px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.87);
    .Select-multi-value-wrapper {
      .Select-placeholder {
        line-height: 44px;
        padding-left: 14px;
      }
      .Select-value {
        background-color: #f7f7f7;
        border: 1px solid #e2e2e2;
        color: #9e9e9e;
        font-size: 13px;
        .Select-value-icon {
          border-right: 1px solid #e2e2e2;
          padding: 0px 5px 0px;
          color: $secondaryColor;
          font-size: 16px;
          &:hover {
            background-color: #f7f7f7;
          }
          &:focus {
            background-color: #f7f7f7;
          }
          &:active {
            background-color: #f7f7f7;
          }
        }
        .Select-value-label {
          padding: 3px 5px 2px 5px;
          color: #9e9e9e;
        }
      }
    }
  }
}

.tabs__container{
  .nav-tabs>li.active>a, .nav-tabs>li.active>a:focus, .nav-tabs>li.active>a:hover {
    color: #73777A;
    cursor: pointer;
    background-color: $white__Color;
    border: 0px solid #ddd;
    border-bottom-color: transparent;
  }
  .nav-tabs>li>a {
    margin-right: 2px;
    line-height: 1.42857143;
    border: 0px solid transparent;
    border-radius: 0;
  }
  ul{
    list-style-type: none;
    padding:0;
    margin:0;
    > li{
      display:inline-block;
      margin: 0 22px 0 0;
      text-align: center;
      // width: calc(100% / 7 - 28px);
      @media #{$tablet}{
        margin: 0 24px 0 24px;
        float: none;
      }
      &:last-child{
        margin: 0;
      }
      > a{
        font-size: 13px;
        font-weight:600;
        color: #787878;
        padding:18px 28px;


        &:hover{
          text-decoration:none;
          background-color: transparent;
          border: 0;
        }
        &:focus{
          text-decoration:none;
          background-color: transparent;
          border: 0;
        }
        &.active{
          color: $secondaryColor;
          border-bottom:2px solid $secondaryColor;
        }
      }
    }
  }
}
.agentFilter {
  flex: unset;
  float: right;
  margin-top: 25px;
  margin-bottom: 5px;
}
.filter__container--filter-name{
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-pack: justify;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  // -webkit-flex-wrap: wrap;
  // flex-wrap: wrap;
  .enteries-label {
    font-size: 14px !important;
    font-weight: 600 !important;
    color: $primaryColor !important;
    margin-right: 10px;
  }
}


.filter__container{
  margin: 27px 0;
  @media #{$phone767}{
    margin: 20px 0;
  }
  .filter__container--title{
    font-size: 15px;
    font-weight: 500;
    color: $primaryColor;
    letter-spacing: 0.44px;
    margin-right: 22px;
    display: inline-block;
  }
  .filter__container--filter-name{
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-pack: justify;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    // -webkit-flex-wrap: wrap;
    // flex-wrap: wrap;
    .enteries-label {
      font-size: 13px;
      font-weight: 500;
      color: #787878;
    }
  }
  .filter__container--button{
    font-size: 13px;
    font-weight: 500;
    color: #787878;
    letter-spacing: 0.38px;
    text-align: center;
    background: #F4F4F4;
    border-radius: 100px;
    height: 40px;
    min-width: 153px;
    padding: 9px 22px;
    line-height: normal;
    margin-right: 18px;
    box-shadow: none;
    border: 2px solid #F4F4F4;
    &.active{
      color: #C6CE4A;
      border: 2px solid #C6CE4A;
      background: $white__Color;
    }
    &:hover,&:focus{
      outline: none;
      text-decoration: none;
    }
    @media #{$phone}{
      min-width: auto;
      padding: 9px 15px;
      margin-right: 10px;
    }
  }
}

.full__page--left{
  h1{
    font-size: 20px;
    font-weight: 600;
    color: #3B3B3B;
    margin: 0 0 11px;
  }
  p{
    font-size: 15px;
    color: #73777A;
  }
}

.full__page--header{
  margin-bottom:63px;
  .report__header {
    padding: 0 !important;
    width: calc(100% - 50px);
    display: inline-block;
  }
  .logo-header{
    padding: 18px 0 0 0;
    img {
      @media #{$phone6,$phone6Plus}{
        width: 170px;
      }
      @media #{$phone360}{
        width: 170px;
      }
    }
    span{
      font-size: 15px;
      font-weight:600;
      color: #73777A;
      vertical-align: -webkit-baseline-middle;
      margin: 0 0 0 24px;
      @media #{$phone6,$phone6Plus}{
        margin: 0 0 0 0;
        display: inline-block;
      }
      @media #{$phone360}{
        margin: 5px 0 0 0;
        display: block;
      }


      .divider{
        margin: 0 17px 0 0;
      }
    }
  }
  .close-btn{
    float:right;
    margin:24px 0 0;
    cursor: pointer;
    @media #{$phone6,$phone6Plus}{
      margin: 20px 0 0;
    }
  }
}

// Client New Css 10-Apr-2020
.btn__flat {
  font-family: $PierSans;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 1.75px;
  border-radius: 0;
  min-height: 40px;
  text-transform: uppercase;
}
.btn__flat__lg {
  min-height: 50px;
}

label.label__text {
  font-family: $PierSans;
  font-size: 12px;
  color: $primaryColor;
  letter-spacing: 1.75px;
  margin-bottom: 5px;
  text-transform: uppercase;
}

.client__home--page {
  .profile-name {
    color: $primaryColor;
  }
  .profile-email {
    color: $primaryColor;
    font-size: 12px;
    font-family: $PierSans;
  }
  .avtar__dropdown {
    .dropdown-menu {
      > li > a {
        font-family: $PierSans;
      }
    }
  }
}
.secondary__cta {
  margin-right: 10px;
  .btn__chat-with-agent,
  .btn__feedback,
  .btn__schedule__appointment {
    background: $secondaryColor;
    padding: 6px;
    color: #fff;
    font-family: $PierSans;
    font-weight: 700;
    font-size: 10px;
    line-height: 28px;
    letter-spacing: 1.75px;
    text-transform: uppercase;
    min-height: 40px;
    border-radius: 0;
  }
  .btn__feedback {
    width: 110px;
    margin-right: 10px;
  }
  .btn__chat-with-agent {
    width: 160px;
  }
  .btn__schedule__appointment {
    width: 190px;
    margin-right: 10px;
  }
}
.agentchatfont__med.strategist__time {
  font-family: $PierSans !important;
}

.client__avatar {
  font-family: $PierSans;
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  background: $secondaryColor;
  text-transform: uppercase;
  white-space: nowrap;
  text-align: center;
  position: relative;
  display: inline-block;
  overflow: hidden;
  vertical-align: middle;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  margin-right: 5px;
  .avatar-string {
    position: absolute;
    left: calc(50%);
    transform-origin: 0 center;
    transform: scale(1) translateX(-50%);
  }
}

.clients__changes__parent {
  .schedule__sidebar--header {
    font-family: $PierSans;
  }
  .selectinputs__global,
  .selectdate__global {
    font-family: $PierSans;
  }
  .center__rate {
    color: $primaryColor;
  }
  .for__edit {
    color: $secondaryColor;
  }
  .profile__header {
    h4 {
      font-family: $PierSans;
      color: $primaryColor;
    }
  }
  .profile__container--form {
    .panel-title {
      color: $primaryColor;
    }
  }
}

// Client New Css 10-Apr-2020


.nav-tabs {
  border-bottom: 0px solid #E6EBF0 !important;
}
.clients__emails--container {
  border: 1px solid #E6EBF0;
}
.client__agent--chat {
  border: 1px solid #E6EBF0;
  float: left;
  width: 100%;
}
// Chat Style
.chat__container{
  border: 1px solid #E6EBF0;
  // border-top: 0;
  margin: 0 0 20px;
  position: relative;
  @media #{$phone6}{
    margin: 0 0 80px 0;
  }
  .chat__container--info-btn{
    position: absolute;
    right: 22px;
    top: 13px;
  }
}
.chat__msg--date__time{
  display: block;
  text-align: center;
  font-size: 13px;
  font-weight: 600;
  color: #787878;
  margin: 10px 0;
}
.chat__msg--avtar{
  width: 33px;
  height: 33px;
  border-radius: 100px;
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
  margin-right: 8px;
  img {
    width: 33px;
    height: 33px;
    object-fit: cover;
  }
}
.chat__msg--avtar-name{
  font-size: 13px;
  font-weight: 600;
  line-height: 21px;
  color: #73777A;
  display: inline-block;
  width: calc(100% - 33px - 8px);
  vertical-align: middle;
  white-space: pre-line;
  word-break: break-word;
}


.incoming_msg_img {
  display: inline-block;
  max-width: 25%;
  width: auto;
  @media #{$tablet}{
    width: 100%;
    max-width: 100%;
    margin-bottom: 10px;
  }
  @media #{$phone}{
    width: 100%;
    max-width: 100%;
    margin-bottom: 10px;
  }
}



.received_msg {
  display: inline-block;
  padding: 0 0 0 10px;
  vertical-align: middle;
  width: 75%;
  @media #{$tablet,$phone}{
    width: 100%;
    padding: 0 0 0 0;
  }
}


.received_withd_msg {
  width: auto;
  max-width: 57%;
  position: relative;
  @media #{$tablet}{
    max-width: 95%;
  }
  @media #{$phone}{
    max-width: 95%;
  }
  p {
    background: #F7F7F5 none repeat scroll 0 0;
    border-radius: 13px;
    color: #73777A;
    font-size: 13px;
    font-weight: 400;
    line-height: 21px;
    white-space: pre-line;
    margin: 0;
    padding: 15px 15px 15px 15px;
    width: auto;
    word-break: break-word;
    float: left;
  }
  a img{
    max-width: 100%;
  }
  .notRecieveToreciever {
    position: absolute;
    top: 5px;
    height: 100%;
    margin-left: 5px;
  }
  .recievedToreciever {
    position: absolute;
    top: 5px;
    height: 100%;
    margin-left: 5px;
  }
}

.mesgs {
  width: 100%;
}
.sent_msg p {
  background: $secondaryColor none repeat scroll 0 0;
  color: $white__Color;
  border-radius: 13px;
  font-size: 13px;
  font-weight: 400;
  white-space: pre-line;
  line-height: 21px;
  margin: 0;
  padding: 15px 13px 15px 21px;
  width: 100%;
}
.sent_msg img {
  padding: 15px 13px 15px 21px;
  max-width: 100%;
}
.outgoing_msg {
  overflow: hidden;
  margin: 13px 0 13px;
  text-align: right;
}
.outgoin_msg_img{
  display: inline-block;
  max-width: 15%;
  text-align: right;
  width: auto;
  @media #{$phone767}{
    width: 100% !important;
    max-width: 100% !important;
  }
  // @media #{$tablet}{
  //   width: 100%;
  //   margin-top: 5px;
  // }
  .chat__msg--avtar{
    margin-right: 0;
    margin-left: 8px;
  }
}
.sent_msg-edit {
  width: 35px;
  display: inline-block;
  margin-right: 15px;
  cursor: pointer;
}
.sent_msg {
  display: inline-block;
  padding: 0 10px 0 0;
  vertical-align: middle;
  max-width: 50%;
  width: auto;
  text-align: left;
}
.chat___msg--input-write .chat___msg--input {
  background:#fff;
  border: medium none;
  // border-right: 1px solid #E6EBF0;
  color: #73777A;
  font-size: 15px;
  min-height: 150px;
  max-height: 150px;
  width: calc( 100% - 147px );
  padding: 16px 32px 15px 32px !important;
  resize: none;
  @media #{$phone}{
    margin-bottom: 0 !important;
    padding: 20px 20px !important;
    min-height: 60px;
    max-height: 60px;
    background: none;
    font-size: 14px;
    border-right: 0 solid #E6EBF0;
    width: calc(100% - 70px);
    line-height: normal;
  }
  &:focus{
    outline: none;
  }
}
.sendnote-agent {
  position: absolute;
  right: 147px;
  height: 56px;
  width: 250px;
  border: 0;
  border-left: 1px solid #E6EBF0;
  padding: 4px 54px 0 25px;
  background-image: url(/img/custom-select-arrow.svg);
  background-size: 12px;
  background-repeat: no-repeat;
  background-position: 90%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  @media #{$tablet}{
    padding: 4px 30px 0 10px;
    background-position: 92%;
    width: auto;
  }
  @media #{$phone}{
    right: 70px;
    padding: 4px 30px 0 10px;
    background-position: 92%;
    width: auto;
  }
}
.type_msg {
  border-top: 1px solid #E6EBF0;
  position: relative;
  @media #{$phone}{
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
.chat__msg--send__btn {
  background: $secondaryColor;
  border: medium none;
  color: $white__Color;
  cursor: pointer;
  font-size: 15px;
  font-weight: 600;
  height: 62px;
  position: absolute;
  right: 0;
  bottom: 0;
  width: 147px;
  @media #{$phone}{
    background: none;
    color: #9B9B9B;
    font-size: 14px;
  }
}
.chat__msg--history {
  min-height: 50px;
  max-height: 459px;
  overflow-y: auto;
  padding: 29px;
  @media #{$phone}{
    padding: 25px 25px 40px 25px;
  }
}
// Chat Style
.d-flex-single {
  display: flex;
}
.d-flex {
  display: flex;
  align-items: center;
  justify-content: center;
}
// .table-responsive {
//   overflow-y: scroll;
// }

// Media Css
@media (max-width:1770px){
  .upcoming__appointments--section {
    .upcoming__appointments--table-list {
      .table > tbody > tr > td {
        padding: 8px 27px;
      }
    }
  }
  .upcoming__appointments--section {
    .upcoming__appointments--table-list {
      .table > thead > tr > th {
        padding: 8px 27px;
        white-space: nowrap;
      }
    }
  }
}



@media (min-width:1366px){
  .sidebar-nav-bottom{
    position: absolute;
    top:inherit;
    bottom: 0;
  }
}

@media (max-width:1366px){
  .tabs__container {
    ul {
      li {
        display: inline-block;
        float: none;
        margin: 0 0 0 18px;
        a {
          padding: 18px 18px;
        }
      }
    }
  }
}

@media (max-width:1360px){
  .tabs__container {
    ul {
      li {
        a {
          padding: 18px 20px;
        }
      }
    }
  }
}

@media (max-width:1246px){
  .tabs__container {
    ul {
      li {
        a {
          padding: 18px 10px;
        }
      }
    }
  }
}


@media (max-width:1200px){
  .rbc-toolbar {
    .rbc-toolbar-label {
      position: absolute;
      left: 200px;
      width: 150px;
    }
    button {
      min-width: 45px;
    }
  }
}


@media (max-width:1100px){
  .rbc-toolbar {
    .rbc-toolbar-label {

    }
  }
}

@media (max-width:1110px){
  .tabs__container {
    ul {
      li {
        a {
          padding: 18px 5px;
        }
      }
    }
  }
  .rbc-btn-group {
    &:last-child {
      position: absolute;
      right: -75px;
    }
  }
  .calendar__page--container {
    .calendar__page--header {
      .appointment-btn {
        right: 50px;
      }
    }
  }
}

@media (max-width:1036px){
  .tabs__container {
    ul {
      li {
        a {
          padding: 18px 0;
        }
      }
    }
  }

}



@media (max-width:1023px){
  .left_bord::after,
  .right_bord::after {
    display: none;
  }
}
@media (max-width:920px){
  .tabs__container {
    .nav-tabs {
      li {
        a {
          padding: 18px 0;
          margin-right: 0 !important;
        }
      }
    }
  }
}
@media (max-width:980px){
  .rbc-calendar {
    margin-top: 84px !important
  }
  .rbc-toolbar {
    left: 0px !important;
    right: 0px !important;
    margin-top: 84px;
    position: fixed !important;
    background: #fff;
    .rbc-toolbar-label {
      position: absolute;
      left: 200px;
      width: 250px;
    }
  }
  .rbc-btn-group {
    &:last-child {
      right: 10px;
    }
  }
}

@media (max-width:767px){
  .mobileFilter {
    .dropdown {
      width: calc(100% - 20px) !important;
    }
  }
  .jungler__page--container {
    .filter__sub--container {
      .custom-select {
        width: calc(100% - 20px) !important;
      }
    }
  }
  .rbc-toolbar {
    position: static !important;
    height: auto !important;
    width: 100%;
    margin-top: 0px;
    display: flow-root;
    .rbc-toolbar-label {
      margin-right: 0;
      text-align: center;
      width: 100%;
      float: left;
      position: static;
    }
    .rbc-btn-group {
      white-space: unset;
      width: 100%;
      position: static;
      float: left;
      text-align: center;
      margin-bottom: 10px;
      margin-top: 10px;
    }
  }
  .rbc-calendar {
    margin-top: 0 !important
  }
  .agent__page--list__info {
    .agent__page--list__info-header--action {
      .form-control.custom-select {
        padding-left: 0 !important;
      }
      .btn {
        margin-left: 0 !important;
        margin-top: 5px;
      }
    }
  }
  .d-flex-brake {
    display: initial;
  }
  .analytics__page--container {
    .analytics__action {
      text-align: left;
    }
    .custom-select {
      width: 100% !important;
      margin-left: 0 !important;
      margin-bottom: 10px;
    }
    .analytics__export--btn {
      margin: 15px auto 0;
      text-align: center;
    }
    .analytics__page--header {
      .custom-select {
        max-width: 200px;
      }
      .analytics__page--close {
        margin-left: 0;
        position: absolute;
        right: 15px;
      }
    }
  }

  .profile__container {
    .profile__container--form {
      .panel-body {
        padding: 33px 0px 27px;
      }
    }
    .locations__list {
      li {
        padding: 15px;
      }
    }
  }


  .dash-dropdown {
    margin-top: 25px !important;
  }   .logo{
        img{
          max-height: 48px;
        }
      }
  .clients__Messages--container {
    .received_withd_msg  {
      max-width: 100%;
    }

    .received_msg {
      width: 95%;;
      @media #{$phone}{
        padding-top: 40px;
      }
    }
  }
  .incoming_msg {
    .incoming_msg_img {
      width: 100%;
    }
  }
  // .outgoin_msg_img {
  //   width: 100%;
  // }
  .sent_msg {
    max-width: 95% !important;
  }
}
// Media Css

.flexdiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 17px;
}
.modalNoHeading{
  display:flex ;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 17px;

}
.popupheader__heading {
  margin: 0;
  padding: 0;
  font-weight: 500;
  font-size: 24px;
  color: $primaryColor;
}
.close_img--white {
  background: none !important;
  border: none !important;
  cursor: pointer;
  img {
    width: 32px;
  }
}
.allpoppadding__para {
  font-family: 'Pier Sans';
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  // color: #606A7D;
  color: $primaryColor;
  margin-bottom: 32px;
  text-align: left;
}
.btns__fullwidth {
  background: $secondaryColor;
  font-family: 'Pier Sans';
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 1.75385px;
  text-transform: capitalize;
  color: #FFFFFF;
  width: 100%;
  border-radius: 3px;
  border: 0;
  padding: 16px 20px;
}
.allpoppadding {
  padding: 24px !important;
}


// New Css for agent Dashboard

// margin / Padding
@for $i from 0 through 50 {
  .mt__#{$i} {
    margin-top: $i * 1px !important;
  }
  .mr__#{$i} {
    margin-right: $i * 1px !important;
  }
  .mb__#{$i} {
    margin-bottom: $i * 1px !important;
  }
  .ml__#{$i} {
    margin-left: $i * 1px !important;
  }
  .pt__#{$i} {
    padding-top: $i * 1px !important;
  }
  .pr__#{$i} {
    padding-right: $i * 1px !important;
  }
  .pb__#{$i} {
    padding-bottom: $i * 1px !important;
  }
  .pl__#{$i} {
    padding-left: $i * 1px !important;
  }
}
.ml__auto {
  margin-left: auto !important;
}
.mr__auto {
  margin-right: auto;
}
.p__0 {
  padding: 0 !important;
}
// margin / Padding

// font Sizes
@for $i from 10 through 24 {
  .font__#{$i} {
    font-size: $i * 1px;
  }
}
// font Sizes
.font__f__pangram {
  font-family: $Pangram;
}

.btn__btn {
  min-height: 48px;
  font-family: $Pangram;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  padding: 6px 12px;
  border-radius: 4px;
  background-color: transparent;
  @media (max-width: 767px){
    font-size: 14px;
  }
  &.btn__xs {
    font-size: 14px;
    min-height: 38px;
  }
}
.btn__link {
  font-weight: 500;
  font-size: 16px;
  color: $secondaryColor;
  background-color: transparent;
  padding: 0;
  &:hover,
  &:focus {
    color: $secondaryColor;
  }
}
.btn__lg {
  min-height: 66px;
  line-height: 48px;
  font-size: 18px;
}
.btn-danger {
  color: $white;
  background-color: $red;
  border-color: $red;
}
.btn-dark {
  color: $white;
  background-color: $primaryColor;
  border-color: $primaryColor;
  &:focus,
  &:hover {
    color: $white;
    background-color: $primaryColor;
    border-color: $primaryColor;
  }
}
.btn-secondry {
  color: $white;
  background-color: #979797;
  border-color: #979797;
  &:focus,
  &:hover {
    color: $white;
    background-color: #979797;
    border-color: #979797;
  }
}
.btn__success {
  color: $white;
  background-color: $secondaryColor;
  border-color: $secondaryColor;
  &:focus,
  &:hover {
    color: $white;
    background-color: $secondaryColor;
    border-color: $secondaryColor;
  }
}
.card {
  position: relative;
  background-color: #fff;
  box-shadow: 5px 5px 20px #E5E5E5;
  -webkit-box-shadow: 5px 5px 20px #E5E5E5;
  -moz-box-shadow: 5px 5px 20px #E5E5E5;
  border-radius: 6px;
  margin-bottom: 15px;
  &.none-shadow {
    box-shadow: none;
  }
  &.border-t-1 {
    border-top: 1px solid #E5E5E5;
  }
  .card-header {
    padding: 12px 25px;
    background-color: #fff;
    border-bottom: 1px solid #E5E5E5;
    min-height: 65px;
    border-radius: 6px 6px 0 0;
    @media (max-width: 767px){
      padding: 12px 15px;
    }
  }
  .card-title {
    font-family: $Pangram;
    font-weight: $bold;
    font-size: 22px;
    line-height: 26px;
    color: $primaryColor;
    @media (max-width: 767px){
      font-size: 18px;
    }
    &.card-title-sm {
      font-size: 16px;
      line-height: 19px;
    }
  }
  .card-subtitle {
    font-size: 12px;
    font-family: $Pangram;
    color: $gray;
    margin-bottom: 0;
  }
  .card-body {
    padding: 20px 45px;
    @media (max-width: 767px){
      padding: 20px 15px;
    }
    &.card-body-sm {
      padding: 25px;
      @media (max-width: 767px){
        padding: 15px;
      }
    }
    &.card-body-square {
      padding: 40px;
      @media (max-width: 767px){
        padding: 15px;
      }
    }
  }
}
.blank__state__text {
  font-family: $Pangram;
  text-align: center;
  color: #979797;
}

.autocomplete-dropdown-container {
  font-family: $Pangram;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 5px 5px 20px #E5E5E5;
  -webkit-box-shadow: 5px 5px 20px #E5E5E5;
  -moz-box-shadow: 5px 5px 20px #E5E5E5;
  max-height: 350px;
  overflow-x: hidden;
  overflow-y: auto;
  .suggestion-item,
  .suggestion-item--active {
    color: $primaryColor;
    display: block;
    padding: 8px 16px;
    cursor: pointer;
    position:static;
    &:hover {
      color: $primaryColor;
      background-color: #f9f9f9;
      text-decoration: none;
    }
  }
}

// .topnavigation-layout-input {
//   &:focus ~ .search-wrapper-open-container{
//     display: block;
//   }
// }
.search-wrapper-open-container {
  font-family: $Pangram;
  background-color: #fff;
  border-radius: 2px;
  border: 1px solid #e5e5e5;
  box-shadow: 5px 5px 20px #E5E5E5;
  -webkit-box-shadow: 5px 5px 20px #E5E5E5;
  -moz-box-shadow: 5px 5px 20px #E5E5E5;
  // display: none;
  list-style-type: none;
  max-height: 350px;
  overflow-x: hidden;
  overflow-y: auto;
  position: absolute;
  text-align: left;
  margin-top: 1px;
  top: 100%;
  width: 100%;
  z-index: 10;
  .search-wrapper-options-menu {
    margin-bottom: 0;
    .search-wrapper-options-menu-item {
      a,
      &.search-wrapper-options-menu-item-other {
        color: $primaryColor;
        display: block;
        padding: 8px 16px;
        cursor: pointer;
        &:hover {
          color: $primaryColor;
          background-color: #f9f9f9;
          text-decoration: none;
        }
      }
      .options-menu-item-title {
        margin: 0;
        .name {
          font-family: $Pangram;
          font-weight: 500;
          font-size: 14px;
          color: $primaryColor;
        }
        .email,
        .phone {
          font-family: $Pangram;
          font-size: 12px;
          color: #4A4A4A;
          margin-left: 36px;
        }
      }
    }
  }
  .avatar {
    font-family: $Pangram;
    position: relative;
    display: inline-block;
    overflow: hidden;
    color: $secondaryColor;
    font-weight: $bold;
    white-space: nowrap;
    text-align: center;
    vertical-align: middle;
    background: rgba(0, 171, 105, 0.15);
    width: 20px;
    height: 20px;
    line-height: 20px;
    border-radius: 50%;
    margin-right: 14px;
  }
  .avatar-string {
    position: absolute;
    left: 50%;
    transform-origin: 0 center;
    transform: scale(1) translateX(-50%);
  }
}

.ant-dropdown.profile--dropdown--overlay {
  &::after {
    content: '';
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 12px solid #E5E5E5;
    position: absolute;
    top: 13px;
    right: 6px;
  }
  .ant-dropdown-menu {
    background: #E5E5E5;
    box-shadow: none;
    border-radius: 6px;
    margin-top: 25px;
    .ant-dropdown-menu-item {
      padding: 11px 20px;
      font-weight: $medium;
      font-size: 14px;
      line-height: 20px;
      color: $primaryColor;
      &:hover {
        background: rgba(0, 0, 0, 0.02);
      }
      > a {
        font-weight: $medium;
        font-size: 14px;
        line-height: 20px;
        color: $primaryColor;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}

// Impersonate
.impersonate {
  // position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  .impersonate__wrapper {
    background: $secondaryColor;
    color: #ffffff;
    font-family: $Pangram;
    font-size: 15px;
    font-weight: 500;
    line-height: 20px;
    padding: 10px 15px;
    text-align: center;
    @media (max-width: 767px){
      font-size: 14px;
    }
    .impersonate__link {
      font-family: $Pangram;
      color: $white;
      margin-left: 5px;
      text-decoration: underline;
      &:hover,
      &:focus {
        color: #12284A;
      }
    }
  }
}

// Impersonate

// Dashboard
.layout-has-sidebar {
  padding-left: 282px;
  min-height: 100vh;
  background: #F5F5F5;
  @media (max-width: 1024px){
    padding-left: 0;
  }
}
.dashboard-layout-content {
  @media (min-width: 767px){
    padding: 20px 15px 20px 0;
  }
}

.date__selector {
  .datepicker-calendar-icon {
    position: absolute;
    bottom: 18px;
    left: 15px;
  }
  select.material-textfield-input {
    padding-left: 40px;
  }
}
.clients__received {
  font-family: $Pangram;
  font-size: 16px;
  line-height: 19px;
  color: $primaryColor;
  margin-top: 6px;
  span {
    font-weight: 800;
    font-size: 48px;
    line-height: 58px;
    color: $primaryColor;
    margin-right: 5px;

  }
}
.expand__list {
  font-family: $Pangram;
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  color: $secondaryColor;
  position: absolute;
  top: 16px;
  right: 22px;
  cursor: pointer;
}
.clients__status__list {
  margin-top: 15px;
  >li {
    a, span {
      font-family: $Pangram;
      font-weight: $medium;
      font-size: 16px;
      line-height: 31px;
      color: $secondaryColor;
    }
  }
}
.total__closed__list {
  margin-top: 15px;
  margin-bottom: 0;
  >li {
    display: flex;
    justify-content: space-between;
    span {
      font-family: $Pangram;
      font-size: 16px;
      line-height: 34px;
      color: $primaryColor;
    }
  }
}
.view__accounting__report {
  font-family: $Pangram;
  font-weight: bold;
  font-size: 16px;
  line-height: 40px;
  color: $secondaryColor;
  &:hover {
    color: $secondaryColor;
  }
}
.total__closed__count {
  font-family: $Pangram;
  font-weight: 800;
  font-size: 30px;
  line-height: 36px;
  color: $secondaryColor;
}

// Dashboard

.heading-title {
  font-family: $Pangram;
  font-weight: $exbold;
  font-size: 32px;
  line-height: 38px;
  color: $primaryColor;
  display: flex;
  align-items: center;
  @media (max-width: 767px){
    font-size: 22px;
    line-height: 29px;
  }
  .heading-icon {
    vertical-align: middle;
    margin-right: 14px;
    svg {
      width: 38px;
      height: 38px;
      @media (max-width: 767px){
        width: 24px;
        height: 24px;
      }
    }
  }
}
.heading-subtitle {
  font-family: $Pangram;
  font-weight: $medium;
  font-size: 18px;
  line-height: 22px;
  color: #979797;
  margin-bottom: 20px;
  @media (max-width: 767px){
    font-size: 16px;
    line-height: 19px;
  }
}
.tabs__control {
  background: #FFFFFF;
  box-shadow: 5px 5px 20px #E5E5E5;
  -webkit-box-shadow: 5px 5px 20px #E5E5E5;
  -moz-box-shadow: 5px 5px 20px #E5E5E5;
  border-radius: 6px;
  display: flex;
  margin: 40px 0 30px;
  &.tabs__control__simple {
    margin: 0;
    box-shadow: none;
    border-bottom: 1px solid #E5E5E5;
  }
  .btn,
  .btn-link {
    border-radius: 0;
    border-right: 1px solid #D8D8D8;
    color: $primaryColor;
    font-family: $Pangram;
    font-size: 16px;
    font-weight: $medium;
    min-height: 64px;
    position: relative;
    width: 100%;
    @media (max-width: 767px){
      font-size: 14px;
    }
    &:focus,
    &:hover {
      color: $primaryColor;
    }
    &:last-child {
      border-right: 0;
    }
    &.active::after {
      content: '';
      height: 4px;
      width: 100%;
      display: block;
      position: absolute;
      left: 0;
      bottom: -1px;
      background-color: $primaryColor;
    }
  }
  span {
    display: block;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: $secondaryColor;
    margin-top: 5px;
    &.red {
      color: #CF0808;
    }
    &.grey {
      color: #979797;
    }
    &.orange {
      color: #FBBC05;
    }
  }
}

.filter__list__flitered {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 10px;
  > li {
    font-family: $Pangram;
    font-weight: $medium;
    font-size: 12px;
    line-height: 14px;
    color: #FFFFFF;
    background: $primaryColor;
    border-radius: 4px;
    padding: 10px 35px 10px 16px;
    position: relative;
    margin-right: 10px;
    margin-bottom: 10px;
    .close-icon {
      width: 10px;
      position: absolute;
      top: 50%;
      right: 14px;
      transform: translateY(-50%);
      cursor: pointer;
      path {
        fill:#fff;
      }
    }
  }
}

.custom-table {
  position: relative;
  z-index: 1;
  margin-bottom: 0;
  &.table td,
  &.table th{
    font-family: $Pangram;
    border-top: 0px solid #dee2e6;
    white-space: nowrap;
  }
  thead {
    &.header-color{
      background-color: #F5F5F5;
    }
    tr {
      th {
        font-weight: $medium;
        font-size: 14px;
        line-height: 17px;
        color: $primaryColor;
        border-bottom: 0 solid #dee2e6;
        padding: 12px 20px;
      }
    }
  }
  tbody {
    // background: #FFFFFF;
    // box-shadow: 5px 5px 20px #E5E5E5;
    -webkit-box-shadow: 5px 5px 20px #E5E5E5;
    -moz-box-shadow: 5px 5px 20px #E5E5E5;
    // border-radius: 6px;
    &:before {
      content: '';
      background: #FFFFFF;
      box-shadow: 5px 5px 20px #E5E5E5;
      -webkit-box-shadow: 5px 5px 20px #E5E5E5;
      -moz-box-shadow: 5px 5px 20px #E5E5E5;
      border-radius: 6px;
      position: absolute;
      top: 41px;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      z-index: -1;
      @media (min-width: 1024px) {
        box-shadow: 5px 5px 20px #E5E5E5;
      }
    }
    tr {
      td {
        font-weight: $medium;
        font-size: 16px;
        color: $primaryColor;
        height: 65px;
        vertical-align: middle;
        padding: 10px 20px;
        position: relative;
        z-index: 1;
        a {
          color: $primaryColor;
          text-decoration: underline;
        }
      }
    }
  }
  tbody tr {
    &:nth-child(even) {
      background-color: #FAFAFA;
    }
  }
  .table-column-title,
  .table-column-sorter {
    display: table-cell;
    vertical-align: middle;
  }
  .table-column-sorter{
    svg {
      margin-left: 5px;
    }
  }
}
.blank__table__state {
  font-family: $Pangram;
  font-size: 16px;
  line-height: 22px;
  color: #979797;
  text-align: center;
  padding: 50px 30px;
  background: #FFFFFF;
  box-shadow: 5px 5px 20px #E5E5E5;
  -webkit-box-shadow: 5px 5px 20px #E5E5E5;
  -moz-box-shadow: 5px 5px 20px #E5E5E5;
  border-radius: 6px;
  width: 100%;
}

.ant-spin {
  max-height: 100%;
}
.ant-spin-spinning {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ant-message {
  font-family: $Pangram;
  .anticon  {
    display: none;
  }
}

.head-section-header {
  min-height: 80px;
  padding: 20px 45px;
  position: relative;
  z-index: 1;
  @media (max-width: 767px){
    padding: 15px;;
  }
  .head-section-title {
    font-family: $Pangram;
    font-weight: $bold;
    font-size: 22px;
    line-height: 26px;
    color: $primaryColor;
  }
  .head-section-cta {
    margin-left: auto;
    .btn-back {

    }
  }
}

.invalid-feedback {
  font-family: $Pangram;
  display: block;
  font-size: 100%;
  color: #DF0000;
}


.selectfield-input-sm {
  font-family: $Pangram;
  font-size: 13px;
  color: $primaryColor;
  border: 2px solid #E5E5E5;
  border-radius: 4px;
  padding: 2px;
  margin-left: 5px !important;
}


// material-Lable style
.material-textfield {
  position: relative;
  &.form-group {
    margin-bottom: 20px;
  }
  .material-textfield-label {
    font-family: $Pangram;
    font-weight: $normal;
    font-size: 14px;
    color: $primaryColor;
    position: absolute;
    pointer-events: none;
    // left: 50px;
    top: 13px;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
    margin-bottom: 0;
    &.label-lg {
      font-size: 16px;
      left: 20px;
      top: 20px;
    }
  }

  .material-textfield-input {
    font-family: $Pangram;
    font-weight: $normal;
    min-height: 48px;
    border: 2px solid #E5E5E5;
    border-radius: 4px;
    padding: 6px 15px;
    &.textfield-input-lg {
      min-height: 66px;
      // &:disabled ~ .material-textfield-label,
      // &:valid ~ .material-textfield-label,
      // &:focus ~ .material-textfield-label {
      // top: 14px;
      //   font-size: 14px;
      // }
    }
    &:hover,
    &:focus {
      border: 2px solid $primaryColor;
    }
    &:focus,
    &:valid {
      padding-top: 20px;
    }
    &:disabled ~ .material-textfield-label,
    &:valid ~ .material-textfield-label,
    &:focus ~ .material-textfield-label {
      top: 8px;
      left: 15px;
      font-family: $Pangram;
      font-weight: $bold;
      font-size: 12px;
      line-height: 14px;
      color: $primaryColor;
    }
    &:disabled ~ .material-textfield-label {
      color: #ccc;
    }
  }
}
// material-Lable style

.react-tel-input {
  font-family: $Pangram;
}
.phone-textfield-input {
  &.material-textfield-input.textfield-input-lg {
    padding-left: 50px;
  }
  ~ label.material-textfield-label.label-lg {
    left: 50px;
  }
}
.flag-textfield.flag-dropdown#flag-dropdown {
  width: 43px;
  padding: 2px 7px;
  min-height: 66px;
  background-color: transparent;
  border: 0;
  .selected-flag {
    background-color: transparent;
    &:focus,
    &:hover {
      background-color: transparent;
    }
    .flag {
      // margin-top: 2px;
    }
  }
}

// chats__card__avatar
.avatar-text {
  font-family: $Pangram;
  font-weight: $medium;
  font-size: 16px;
  line-height: 19px;
  color: $primaryColor;
  margin-right: 15px;
  span {
    color: #979797;
  }
}

.avatar {
  color: $secondaryColor;
  font-family: $Pangram;
  font-weight: $bold;
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  background: $white;
  position: relative;
  &.avatar-lg{
    width: 80px;
    height: 80px;
    font-size: 22px;
    line-height: 80px;
    border-radius: 100px;
  }
  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 100px;
  }
  .avatar-string {
    position: absolute;
    left: 50%;
    transform-origin: 0 center;
    transform: scale(1) translateX(-50%);
  }
  .upload-avatar-container {
    text-align: center;
    width: 34px;
    height: 34px;
    line-height: 34px;
    background: $secondaryColor;
    border-radius: 100px;
    display: inline-block;
    position: absolute;
    bottom: 0;
    right: 0;
    cursor: pointer;
  }
  .upload-avatar {
    &[type=file] {
      cursor: pointer;
      width: 100%;
      height: 100%;
      overflow: hidden;
      font-size: 0;
      position: absolute;
      z-index: 1;
    }
    &[type=file]:before {
      width: 100%;
      height: 100%;
      content: '';
      display: inline-block;
    }
    &[type=file]::-webkit-file-upload-button {
      visibility: hidden;
    }
  }
}
// Avatar

.card-info {
  background: rgba(251, 188, 5, 0.1);
  border-radius: 4px;
  padding: 10px 20px 10px;
  .card-info-inner {
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    color: #FBBC05;
    display: flex;
    align-items: center;
  }
  .card-info-icon {
    min-width: 27px;
    margin-right: 18px;
  }
}

.card__tile {
  background: #FFFFFF;
  border: 2px solid transparent;
  box-shadow: 5px 5px 20px #E5E5E5;
  -webkit-box-shadow: 5px 5px 20px #E5E5E5;
  -moz-box-shadow: 5px 5px 20px #E5E5E5;
  border-radius: 6px;
  padding: 20px 26px;
  display: flex;
  align-items: center;
  transition: all 0.2s ease;
  margin-bottom: 15px;
  cursor: pointer;
  &.br__0 {
    border-radius: 0;
  }
  @media (max-width: 767px){
    padding: 15px;
  }
  &:hover {
    border: 2px solid $secondaryColor;
    box-shadow: 5px 5px 20px #E5E5E5;
    -webkit-box-shadow: 5px 5px 20px #E5E5E5;
    -moz-box-shadow: 5px 5px 20px #E5E5E5;
  }
  .card__tile__icon {
    margin-right: 12px;
    min-width: 46px;
    width: 46px;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    .chat-icon {
      width: 26px;
    }
    .track-icon {
      width: 20px;
      circle {
        fill: #979797;
      }
    }
    .calendar-icon {
      width: 18px;
      path {
        fill: #979797;
      }
    }
    &.green {
      background-color: $backgroundGray;
      .calendar-icon {
        path {
          fill: $secondaryColor;
        }
      }
    }
    &.red{
      background-color: rgba(207, 8, 8, 0.10);
      svg {
        path {
          fill: #CF0808;
        }
      }
      .track-icon {
        circle {
          fill: #CF0808;
        }
        path {
          fill: #fff;
        }
      }
      ~ .card__tile__arrow {
        svg {
          path {
            fill: #CF0808;
          }
        }
        .card__tile__count {
          color: #CF0808;
        }
      }
    }
    &.grey{
      background-color: rgba(151, 151, 151, 0.10);
    }
    &.orange{
      background-color: rgba(251, 188, 5, 0.15);
      svg {
        path {
          fill: #FBBC05;
        }
      }
      ~ .card__tile__arrow {
        svg {
          path {
            fill: #FBBC05;
          }
        }
        .card__tile__count {
          color: #FBBC05;
        }
      }
    }
  }
  .card__tile__content {

  }

  .card__tile__arrow {
    margin-left: auto;
    display: flex;
    align-items: center;
    svg {
      vertical-align: middle;
    }
    .card__tile__count {
      font-family: $Pangram;
      font-weight: 800;
      font-size: 30px;
      line-height: normal;
      color: #979797;
      vertical-align: middle;
      margin-right: 17px;
    }
  }
}
.card__tile__title {
  font-family: $Pangram;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: $primaryColor;
  margin: 0 0 5px;
}
.card__tile__subtitle {
  font-family: $Pangram;
  font-size: 14px;
  line-height: 22px;
  color: #9B9B9B;
}

.card__tile__other {
  padding: 20px 30px;
  @media (max-width: 767px){
    padding: 15px;
  }
  .card__tile__title {
    @extend .card__tile__title;
  }
  .card__tile__subtitle {
    @extend .card__tile__subtitle;
  }
}
svg {
  vertical-align: middle;
}

// Price min/max on Strategist edit page
div.strategist_price_range {
  margin-top: 10px;
  label.label__strategist_price_min {
    font-size: 13px;
    font-family: $Pangram;
    font-weight: normal;
  }
  input.field__strategist_price {
    border: 1px solid gray;
    margin-left: 15px;
    font-size: 13px;
    padding: 3px;
  }
}




// React Modal Css
.ReactModal__Body--open{
  overflow: hidden !important;
}
.react-modal {
  .ReactModal__Overlay.ReactModal__Overlay--after-open {
    overflow-x: hidden;
    overflow-y: auto;
  }
  .ReactModal__Overlay.ReactModal__Overlay--after-open::before {
    content:'';
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.80);
    z-index: -1;
  }
  .ReactModal__Overlay {
    z-index: 1050;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;
    background-color: transparent !important;
    z-index: 1072;
    display: flex;
    justify-content: center;
  }
  .ReactModal__Content.ReactModal__Content--after-open{
    // position: static  !important;
    top: auto !important;
    left: auto !important;
    right: auto !important;
    bottom: auto !important;
    border: none !important;
    background: transparent  !important;
    overflow: visible !important;
    border-radius: 0 !important;
    outline: none !important;
    padding: 0 !important;
    // width: calc(100%) !important;
    margin-right: 0 !important;
    transform: none !important;
    min-height: calc(100%);
    display: flex;
    align-items: center;
    @media (max-width: 767px) {
      width: calc(100% - 1.75rem);
    }
  }
  .react-modal-dialog  {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    border-radius: 6px;
    outline: 0;
    overflow: initial !important;
    transition: all 0.2s ease;
  }

  .react-modal-dialog {
    min-width: 600px;
    max-width: 600px;
    margin: 1.75rem auto;
    @media (max-width: 767px) {
      min-width: 100%;
    }
    &.react-modal-dialog-xs {
      min-width: 420px;
      max-width: 420px;
      @media (max-width: 767px) {
        min-width: 100%;
      }
    }
    &.react-modal-dialog-sm {
      min-width: 700px;
      max-width: 700px;
      @media (max-width: 767px) {
        min-width: 100%;
      }
    }
    &.react-modal-dialog-md {
      min-width: 960px;
      max-width: 960px;
      @media (max-width: 767px) {
        min-width: 100%;
      }
    }
    &.react-modal-dialog-lg {
      min-width: 1024px;
      max-width: 1024px;
      @media (max-width: 767px) {
        min-width: 100%;
      }
    }
    &.react-modal-dialog-xl {
      min-width: 1200px;
      max-width: 1200px;
      @media (max-width: 767px) {
        min-width: 100%;
      }
    }
  }
  .react-modal-dialog.react-modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .react-modal-dialog-centered {
    min-height: calc(100% - 1rem);
  }
  .react-modal-header {
    padding: 25px;
    border-bottom: 1px solid #E5E5E5;
    @media (max-width: 767px){
      padding: 15px;
    }
  }
  .react-modal-title {
    font-family: $Pangram;
    font-weight: $bold;
    font-size: 22px;
    line-height: 26px;
    color: $primaryColor;
    margin-bottom: 0;
    @media (max-width: 767px){
      font-size: 16px;
    }
    &.react-modal-title-sm {
      font-size: 18px;
      line-height: 22px;
    }
  }
  .react-modal-subtitle {
    font-family: $Pangram;
    font-size: 16px;
    line-height: 25px;
    color: $primaryColor;
    margin-bottom: 0;
  }
  .react-modal-close {
    position: absolute;
    top: 17px;
    right: 10px;
    z-index: 10;
    background-color: transparent;
    @media (max-width: 767px){
      top: 8px;
      right: 5px;
      svg {
        width: 18px;
      }
    }
    &.react-modal-close-sm {
      svg {
        width: 16px;
        height: 16px;
      }
    }
  }
  .react-modal-body {
    padding: 25px 34px;
    @media (max-width: 767px){
      padding: 15px;
    }
    &.react-modal-body-sm {
      padding: 25px;
    }
  }
}
// React Modal Css

// Basic
.d__flex {
  display: flex;
}
.align__items__start {
  align-items: flex-start;
}
.align__items__center {
  align-items: center;
}
.align__items__end {
  align-items: flex-end;
}
.justify__content__start {
  justify-content: flex-start;
}
.justify__content__center {
  justify-content: center;
}
.justify__content__end {
  justify-content: flex-end;
}
.justify__content__between {
  justify-content: space-between;
}


.w__100 {
  width: 100%;
}
.w__130 {
  width: 130px;
}
.w__140 {
  width: 140px;
}
.w__150 {
  width: 150px !important;
}
.w__160 {
  width: 160px !important;
}
.w__180 {
  width: 180px;
}
.w__200 {
  width: 200px;
}
.w__300 {
  width: 300px;
}
.text__grey {
  color: #9B9B9B;
}
.text__black {
  color: $primaryColor;
}
// Basic

.client__details__header {
  margin-bottom: 20px;
  .client__details__header__cta {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap:wrap;
    .btn__follow-up-date {
      max-width: 153px;
    }
  }
}

.btn-back {
  font-family: $Pangram;
  background: transparent;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: $primaryColor;
  display: flex;
  align-items: center;
  padding: 13px 0;
  &:hover,
  &:focus {
    box-shadow: none;
    outline: none;
  }
  .arrow-icon {
    margin-right: 17px;
  }
}

.info__block {
  margin-bottom: 20px;
  label {
    font-family: $Pangram;
    color: $primaryColor;
    margin-bottom: 0;
  }
  p{
    font-family: $Pangram;
    font-size: 16px;
    line-height: 23px;
    // color: $primaryColor;
    margin-bottom: 0;
  }
  a {
    color: $primaryColor;
    text-decoration: underline;
  }
  .nottouricon-icon {
    margin-left: -30px;
    margin-right: 9px;
    path {
      fill: #FBBC05;
    }
  }
}

.chats__sectiion {
  .chats__sectiion__title {
    font-family: $Pangram;
    font-weight: bold;
    font-size: 22px;
    line-height: 30px;
    color: $primaryColor;
    margin-bottom: 2px;
    @media (max-width: 767px){
      font-size: 18px;
    }
  }
  .chats__sectiion__subtitle {
    font-family: $Pangram;
    font-size: 12px;
    line-height: 22px;
    color: #9B9B9B;
    margin-bottom: 15px;
  }
  .chats__sectiion__wrapper {
    width: 100%;
    background: #FFFFFF;
    border: 1px solid #D8D8D8;
    border-radius: 4px;
    margin-bottom: 20px;
    .chats__sectiion__input {
      font-family: $Pangram;
      font-size: 16px;
      color: $primaryColor;
      background: transparent;
      width: 100%;
      border: 0;
      resize: none;
      padding: 17px 20px;
      &:focus {
        outline: none;
      }
    }
    .chats__section__cta {
      width: 100%;
      padding: 11px 20px;
      display: flex;
      align-items: center;
      .btn__send {
        margin-right: 15px;
        min-width: 127px;
        min-height: 48px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      label {
        cursor: pointer;
      }
    }
  }
  .chats__sectiion__inner {
    .chats__sectiion__chats__list {
      > li {
        &.has__avatar {
          padding-left: 42px;
          .chats__card__header {
            margin-left: -42px;
          }
          + .btn__view_more_chat {
            margin-left: 42px;
          }
        }
        // margin-bottom: 24px;
        // &:last-child {
        //   margin-bottom: 0;
        // }
      }
    }
    .chats__card__header {
      display: flex;
      align-items: center;
      min-height: 42px;
      .chats__card__avatar {
        line-height: 40px;
        background: #b6bbbe;
        border-radius: 50%;
        color: #fff;
        display: inline-block;
        font-family: $Pangram;
        font-weight: 600;
        font-size: 16px;
        letter-spacing: 1.5px;
        height: 42px;
        margin-right: 13px;
        overflow: hidden;
        position: relative;
        text-align: center;
        vertical-align: middle;
        white-space: nowrap;
        width: 42px;
        > img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 100px;
        }
        .string__avatar {
          position: absolute;
          left: calc(50% + 1.5px);
          -webkit-transform-origin: 0 center;
          -ms-transform-origin: 0 center;
          transform-origin: 0 center;
          -webkit-transform: scale(1) translateX(-50%);
          -ms-transform: scale(1) translateX(-50%);
          transform: scale(1) translateX(-50%);
        }
      }
      .chats__card__name,
      .chats__card__time {
        font-family: $Pangram;
        font-weight: bold;
        font-size: 16px;
        line-height: 22px;
        color: $primaryColor;
      }
      .chats__card__time {
        font-size: 12px;
        font-weight: $normal;
        margin-left: 5px;
      }
    }
    .chats__card {
      font-family: $Pangram;
      white-space: pre-line;
      font-size: 16px;
      line-height: 22px;
      color: $primaryColor;
      background: #FFFFFF;
      box-shadow: 5px 5px 20px #E5E5E5;
      -webkit-box-shadow: 5px 5px 20px #E5E5E5;
      -moz-box-shadow: 5px 5px 20px #E5E5E5;
      border-radius: 6px;
      padding: 15px 20px;
      // word-break: break-all;
    }
    .chats__card__edit {
      margin-top: 15px;
      .chats__card__textarea__edit {
        font-family: "Pangram";
        font-size: 16px;
        color: $primaryColor;
        padding: 17px 20px;
        width: 100%;
        background: #FFFFFF;
        border-radius: 4px;
        border: 1px solid #D8D8D8;
        resize: vertical;
        margin-bottom: 5px;
        &:focus {
          outline: none;
          border: 1px solid $primaryColor;
        }
      }
      .chats__card__edit__close {
        width: 18px;
        cursor: pointer;
      }
    }
    .chats__card__cta {
      margin: 10px 0;
    }
    .btn__edit_chat,
    .btn__delete_chat {
      font-family: $Pangram;
      font-weight: $medium;
      font-size: 14px;
      line-height: 22px;
      text-decoration: underline;
      background-color: transparent;
      color: #B4B4B4;
      padding: 0;
      margin-right: 15px;
      &:hover ,
      &:focus {
        color: $secondaryColor;
      }
    }
    .btn__view_more_chat {
      background: transparent;
      font-family: $Pangram;
      font-weight: bold;
      font-size: 14px;
      line-height: 22px;
      color: $secondaryColor;
      padding: 5px 0;
      margin: 10px 0;
    }
  }
}

.cursor__pointer {
  cursor: pointer;
}

.filter__result__section {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @media (max-width: 767px){
    flex-wrap: wrap;
  }
}
.btn-filter {
  min-width: 149px;
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  .filter-icon {
    margin-right: 10px;
  }
}
.btn__add__new__client {
  min-width: 180px;
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  @media (max-width: 767px){
    width: 100%;
    margin-right: 0;
    margin-bottom: 15px;
  }
}
.btn__add__jungler__user {
  min-width: 180px;
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  @media (max-width: 767px){
    width: 100%;
    margin-right: 0;
    margin-bottom: 15px;
  }
}
.results__select {
  min-width: 125px;
  max-width: 125px;
  &.material-textfield-input {
    min-height: 50px;
  }
}
.status__select {
  min-width: 203px;
}
.status__follow-up {
  min-width: 153px;
}
.cleint__information__cta {
  display: flex;
  align-items: center;
  margin-left: auto;
  .material-textfield {
    @media (max-width: 767px){
      display: none;
    }
  }
  .client__info__status__select {
    margin-right: 21px;
    width: 203px;
  }
}
.btn__create__appointment {
  min-width: 217px;
  @media (max-width: 767px){
    min-width: 180px;
  }
}
.btn__edit__client {
  min-width: 200px;
  line-height: 35px;
  @media (max-width: 767px){
    min-width: 140px;
  }
}
.background__profile__text {
  font-family: $Pangram;
  color: $primaryColor;
  white-space: pre-line;
}
.deal__information__cta {
  margin-left: auto;
  @media (max-width: 767px) {
    display: flex;
    align-items: center;
  }
  .btn__edit__deal,
  .btn__delete__deal {
    min-width: 141px;
    @media (max-width: 767px) {
      min-width: 90px;
    }
  }
}

.deal__progress{
  margin: 0 0 23px;
  .deal__progress__item{
    position: relative;
    z-index: 1;
    >li{
      display: inline-block;
      width: calc(100% / 3);
      text-align: center;
      position: relative;
      vertical-align: text-top;
      &:before{
        content: '';
        width:30px;
        height: 30px;
        border: 1px solid #D8D8D8;
        background: $white;
        border-radius: 50%;
        display: block;
        margin-left: 50%;
        transform: translateX(-50%);
        margin-bottom: 10px;
      }
      &:after{
        content: '';
        display: block;
        background: #E5E5E5;
        width:100%;
        height: 1px;
        top: 15px;
        left: 50%;
        position: absolute;
        z-index: -1;
      }
      &:last-child:after{
        content: none;
      }
      &.active{
        &:before{
          background: $secondaryColor url('/img/img_v2/checkmark.svg') no-repeat center;
          border: 1px solid $secondaryColor;
        }
      }
      h2,
      h4{
        font-family: $Pangram;
        font-weight: bold;
        font-size: 12px;
        text-align: center;
        color: $primaryColor;
      }
      h4{
        font-size: 13px;
        font-weight: 400;
      }
    }
  }
}

.notificaton__modal__overlay  {
  width: 100%;
  max-width: 585px;
  .ant-popover-inner {
    box-shadow: 5px 5px 20px #E5E5E5;
    -webkit-box-shadow: 5px 5px 20px #E5E5E5;
    -moz-box-shadow: 5px 5px 20px #E5E5E5;
    border-radius: 6px;
  }
  .ant-popover-inner-content {
    padding: 0;
  }
}
.notificaton__container {
  .notificaton__container__header {
    border-bottom: 1px solid #D8D8D8;
    padding: 20px 0;
    .notificaton__container__content{
      font-family: $Pangram;
      font-weight: bold;
      font-size: 22px;
      line-height: 26px;
      color: $primaryColor;
      text-align: center;
    }
    .btn__close__notify {
      background-color: transparent;
      padding: 0;
      position: absolute;
      top: 30px;
      right: 22px;
      z-index: 1;
    }
  }
  .margin__ten {
    margin: 10px;
  }
  .padding__less {
    padding: 0 15px 0px !important;
  }
  .notificaton__container__body {
    padding: 0 25px 15px;
    overflow: auto;
    max-height: 400px;
    .notificaton__blank__text {
      font-family: $Pangram;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      color: #979797;
      text-align: center;
      padding: 30px 10px;
    }
    .btn__clear_all {
      font-family: $Pangram;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      text-decoration: underline;
      color: $primaryColor;
      margin: 6px 0;
      background-color: transparent;
      padding: 9px 0;
      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
  }
  .notificaton__list {
    background: #FFFFFF;
    box-shadow: 5px 5px 20px #E5E5E5;
    -webkit-box-shadow: 5px 5px 20px #E5E5E5;
    -moz-box-shadow: 5px 5px 20px #E5E5E5;
    border-radius: 6px;
    margin-bottom: 20px;
    cursor: pointer;
    // margin-left: 45px;
    > li {
      position: relative;
      // &::before {
      //   content: '';
      //   width: 30px;
      //   height: 30px;
      //   background-color: #fff;
      //   border: 2px solid $primaryColor;
      //   display: inline-block;
      //   border-radius: 50px;
      //   position: absolute;
      //   left: -45px;
      //   top: 50%;
      //   transform: translateY(-50%);
      // }
      &.active {
        // &::before {
        //   background-color: $primaryColor;
        //   border: 2px solid $primaryColor;
        // }
        &:first-child {
          border-radius: 6px 6px 0 0;
        }
        &:last-child {
          // border-radius: 0 0 6px 6px;
        }
        background-color: $primaryColor;
        .notificaton__card {
          background-color: #fff;
        }
        .notificaton__title,
        .notificaton__delivery {
          color: #fff;
        }
      }
    }
    .notificaton__item {
      padding: 15px 24px;
      .notificaton__title {
        font-family: $Pangram;
        font-size: 14px;
        line-height: 23px;
        color: $primaryColor;
        margin-bottom: 10px;
      }
      .notificaton__card {
        font-family: $Pangram;
        font-size: 14px;
        line-height: 22px;
        color: $primaryColor;
        padding: 10px 20px;
        margin-bottom: 10px;
        background: #E5E5E5;
        border-radius: 6px;
        white-space: pre-line;
      }
      .notificaton__delivery {
        font-family: $Pangram;
        font-size: 12px;
        line-height: 23px;
        color: #111111;
      }
    }
  }
}

.btn__cal__sync {
  font-family: $Pangram;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  background-color: transparent;
  border: 2px solid transparent;
  border-radius: 4px;
  width: 100%;
  min-height: 48px;
  margin-bottom: 14px;
  display: flex;
  align-items: center;
  .google-icon,
  .outlook-icon {
    margin-right: 14px;
  }
  &.google {
    color: #0F9D58;
    border: 2px solid #0F9D58;
    &.synced {
      border: 0;
      padding: 0;
    }
  }
  &.outlook {
    color: #0072C6;
    border: 2px solid #0072C6;
    &.synced {
      border: 0;
      padding: 0;
    }
  }
  .btn__unsync {
    margin-left: 14px;
    width: 104px;
    color: #fff;
    background-color: #892F2F;
    border-color: #892F2F;
  }
}

.calendar__small {
  width: 100%;
  border: 0px solid #a0a096;
  font-family: $Pangram;
  .react-calendar__navigation {
    position: relative;
    height: 20px;
    margin-bottom: 0;
    .react-calendar__navigation__prev-button,
    .react-calendar__navigation__next-button {
      position: absolute;
      top: -8px;
      right: 0;
      padding: 10px 15px;
      font-size: 0;
      border-radius: 6px;
    }
    .react-calendar__navigation__prev-button{
      right: 44px;
      background: url('/img/img_v2/caret-left.svg') no-repeat center;
    }
    .react-calendar__navigation__next-button {
      background: url('/img/img_v2/caret-right.svg') no-repeat center;
    }
    .react-calendar__navigation__prev2-button,
    .react-calendar__navigation__next2-button {
      display: none;
    }
    .react-calendar__navigation__label {
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: $primaryColor;
      text-align: left;
      &:hover {
        background-color: transparent;
      }
    }
  }
  .react-calendar__month-view__weekdays {
    border-bottom: 1px solid #C8C9CC;
    .react-calendar__month-view__weekdays__weekday {
      font-size: 13px;
      font-weight: $normal;
      line-height: 16px;
      text-align: center;
      letter-spacing: 0.2px;
      color: #C8C9CC;
      text-transform: capitalize;
      padding: 15px 0.2em;
      abbr[title] {
        cursor: default;
        text-decoration: none;
      }
    }
  }
  .react-calendar__month-view__days {
    .react-calendar__tile {
      font-weight: 500;
      border-radius: 100px;
      padding: 12px;
      &.react-calendar__tile--active  {
        color: #fff;
        background: $primaryColor !important;
        &.react-calendar__month-view__days__day--weekend {
          color: #fff !important;
        }
      }
      &.react-calendar__month-view__days__day--weekend {
        color: $primaryColor !important;
        &.react-calendar__month-view__days__day--neighboringMonth {
          color: #E1E1E1 !important;
        }
      }
      &.react-calendar__month-view__days__day--neighboringMonth {
        color: #E1E1E1;
      }

    }
  }
}

.alert__info__indicated {
  background: rgba(251, 188, 5, 0.1);
  border-radius: 4px;
  color: #FBBC05;
  display: flex;
  align-items: center;
  font-family: $Pangram;
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  padding: 10px 20px;
  width: 100%;
  .indicated-icon {
    min-width: 22px;
    height: 25px;
    margin-right: 13px;
    path {
      fill: #FBBC05;
    }
  }
  .indicated-icon2 {
    min-width: 22px;
    height: 25px;
    margin-right: 13px;
    path {
      fill: red;
    }
  }
}

.alert__info__indicated2 {
  background: rgba(251, 188, 5, 0.1);
  border-radius: 4px;
  color: red;
  display: flex;
  align-items: center;
  font-family: $Pangram;
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  padding: 10px 20px;
  width: 100%;
  text-align: center;

  .indicated-icon2 {
    min-width: 22px;
    height: 25px;
    margin-right: 13px;
    path {
      fill: #cf0808;
    }
  }
}

// Filter
.filter__overlay  {
  width: 100%;
  max-width: 460px;
  .ant-popover-inner {
    box-shadow: 5px 5px 20px #E5E5E5;
    -webkit-box-shadow: 5px 5px 20px #E5E5E5;
    -moz-box-shadow: 5px 5px 20px #E5E5E5;
    border-radius: 6px;
  }
  .ant-popover-inner-content {
    padding: 0;
  }
}
.filter {
  .filter__header__note {
    text-align: center;
    padding: 8px 13px;
    background-color: $secondaryColor;
    color: $white;
    font-size: 12px;
  }
  .filter__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 13px;
    .filter__title {
      font-family: $Pangram;
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 1px;
      color: $primaryColor;
    }
    .btn {
      min-width: 90px;
    }
  }
  .filter__body {
    max-height: 330px;
    overflow: auto;
  }
}
.filter__list {
  > li {
    padding: 19px 13px;
    border-bottom: 1px solid #D8D8D8;
    &:last-child {
      border-bottom: 0px solid #D8D8D8;
    }
  }
}
.filter__list__inner {
  margin-left: 43px;
  padding: 0 13px;
  > li {
    padding: 10px 0;
  }
}
// Filter


// Checkbox
.checkbox-list{
  &.vertical {
    > li {
      margin-bottom: 17px;
    }
  }
  &.horizontal {
    > li {
      display: inline-block;
      margin-right: 21px;
    }
  }
}
.custom-checkbox {
  display: block;
  position: relative;
  padding-left: 42px;
  margin-bottom: 0;
  cursor: pointer;
  font-family: $Pangram;
  color: $primaryColor;
  font-weight: $medium;
  font-size: 16px;
  line-height: 30px;
  .custom-checkbox--input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  .custom-checkbox-checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 30px;
    width: 30px;
    background-color: #fff;
    border: 2px solid #C4C4C4;
    border-radius: 4px;
  }
  .custom-checkbox--input:checked ~ .custom-checkbox-checkmark {
    background-color: $primaryColor;
    border: 2px solid $primaryColor;
  }
  .custom-checkbox-checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }
  .custom-checkbox--input:checked ~ .custom-checkbox-checkmark:after {
    display: block;
  }
  .custom-checkbox-checkmark:after {
    left: 10px;
    top: 5px;
    width: 6px;
    height: 13px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
}

.custom-checkbox-line {
  line-height: 24px;
  display: inline-block;
  vertical-align: middle;
  .custom-checkbox-checkmark {
    height: 24px;
    width: 24px;
    background-color: #fff;
    border: 1px solid $primaryColor;
    border-radius: 0;
    &:after {
      left: 7px;
      top: 2px;
    }
  }
}
// Checkbox

select.custom__select{
  height: 54px;
  border: 1px solid #C8C9CC;
  border-radius: 4px;
  background-color: $white__Color;
  font-family: $Pangram;
  font-weight: $medium;
  font-size: 16px;
  line-height: 19px;
  color: $primaryColor;
  color: $primaryColor;
  padding: 7px 25px 7px 12px;
  padding-right: 32px !important;
  -webkit-appearance:none;
  overflow: hidden;
  background-image:url(/img/img_v2/select-icon.svg);
  background-size: 16px;
  background-repeat:no-repeat;
  background-position: calc(100% - 15px) center;
  appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  cursor:pointer;
  text-transform: capitalize;
  &:focus{
    border: 1px solid $primaryColor;
  }
}

// DatePicker btn
.btn__follow-up-date {
  background-color: #fff;
  border-radius: 4px;
  border: 2px solid #E5E5E5;
  display: inline-block;
  position: relative;
  width: 100%;
  min-width: 150px;
  &:hover {
    border: 2px solid $primaryColor;
  }
  &.follow-up-date-lg {
    .datepicker__follow-up-date {
      &.ant-picker {
        padding: 28px 15px 9px;
        .ant-picker-input {
          > input {
            font-size: 16px;
            color: $primaryColor;
            cursor: pointer;
            &::placeholder {
              color: $primaryColor;
            }
          }

        }
      }
    }
    .datepicker__follow-up-date-red {
      &.ant-picker {
        padding: 28px 15px 9px;
        .ant-picker-input {
          > input {
            font-size: 16px;
            color: #CF0808;
            cursor: pointer;
            &::placeholder {
              color: $primaryColor;
            }
          }

        }
      }
    }
  }
  .datepicker__follow-up-date {
    &.ant-picker {
      width: 100%;
      padding: 22px 15px 4px;
      border: 0;
      font-family: $Pangram;
      color: $primaryColor;
      cursor: pointer;
      z-index: 1;
      background: transparent;
      .ant-picker-input {
        > input {
          color: $primaryColor;
          cursor: pointer;
          &::placeholder {
            color: $primaryColor;
          }
        }
        .ant-picker-suffix {
          display: none;
        }
      }
    }
    &.ant-picker-focused {
      box-shadow: none;
      border: 0;
    }
  }
  .datepicker__follow-up-date-red {
    &.ant-picker {
      width: 100%;
      padding: 22px 15px 4px;
      border: 0;
      font-family: $Pangram;
      color: $primaryColor;
      cursor: pointer;
      z-index: 1;
      background: transparent;
      .ant-picker-input {
        > input {
          color: #CF0808;
          cursor: pointer;
          &::placeholder {
            color: $primaryColor;
          }
        }
        .ant-picker-suffix {
          display: none;
        }
      }
    }
    &.ant-picker-focused {
      box-shadow: none;
      border: 0;
    }
  }
  .follow-up-date-label {
    font-family: $Pangram;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    color: $primaryColor;
    position: absolute;
    z-index: 1;
    top: 6px;
    left: 15px;
  }
  .caret-icon {
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
    z-index: 0;
  }
}
.btn__follow-up-date-other {
  &:hover {
    border: 2px solid #E5E5E5;
  }
  &.follow-up-date-lg {
    .datepicker__follow-up-date {
      &.ant-picker {
        padding: 28px 15px 9px;
        .ant-picker-input {
          > input {
            cursor: not-allowed;
          }

        }
      }
    }
    .datepicker__follow-up-date-red {
      &.ant-picker {
        padding: 28px 15px 9px;
        .ant-picker-input {
          > input {
            cursor: not-allowed;
          }

        }
      }
    }
  }
  .datepicker__follow-up-date {
    &.ant-picker {
      cursor: not-allowed;
      .ant-picker-input {
        > input {
          cursor: not-allowed;
        }
      }
    }
  }
  .datepicker__follow-up-date-red {
    &.ant-picker {
      cursor: not-allowed;
      .ant-picker-input {
        > input {
          cursor: not-allowed;
        }
      }
    }
  }
}
.datepicker__overlay__follow-up-date {
  font-family: $Pangram;
  z-index: 9999;
  .ant-picker-panel-container {
    // .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner{
    //   color: #fff;
    //   background: $secondaryColor;
    // }
    .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
      border: 1px solid $secondaryColor;
    }
    .ant-picker-cell-selected {
      .ant-picker-cell-inner {
        background: $secondaryColor;
      }
    }
    .ant-picker-footer {
      display: none;
      .ant-picker-today-btn {
        color: $secondaryColor;
        &:hover {
          color: $secondaryColor;
        }
      }
    }
  }
}
// DatePicker btn

.date-range-picker {
  font-family: $Pangram;
  margin-bottom: 25px;
  .rdr-MonthAndYear {
    .rdr-MonthAndYear-innerWrapper {
      font-size: 16px !important;
      .rdr-MonthAndYear-button.prev,
      .rdr-MonthAndYear-button.next {
        i {
          display: none !important;
        }
      }
      .rdr-MonthAndYear-button.prev{
        background: url('/img/img_v2/caret-left.svg') no-repeat center !important;
      }
      .rdr-MonthAndYear-button.next {
        background: url('/img/img_v2/caret-right.svg') no-repeat center !important;
      }
    }
  }
  .rdr-WeekDays {
    .rdr-WeekDay {
      font-size: 14px !important;
    }
  }
  .rdr-Days {
    .rdr-Day {
      font-size: 14px !important;
      border-radius: 50px !important;
      &.is-selected {
        background: $secondaryColor !important;
      }
      &.is-inRange {
        color: #fff !important;
        background: $secondaryColor !important;
      }
    }
  }
}

hr {
  margin-top: 30px;
  margin-bottom: 30px;
  border-top: 1px solid #D8D8D8;
}

.btn__impersonate {
  font-family: $Pangram;
  font-weight: 500;
  font-size: 16px;
  color: $secondaryColor;
  background-color: transparent;
  &:hover,
  &:focus {
    color: $secondaryColor;
  }
}

.search__form {
  margin: 20px 0;
  .search__form__input {
    font-family: $Pangram;
    border: 2px solid #E5E5E5;
    border-radius: 4px;
    height: 54px;
    font-size: 14px;
    &::placeholder {
      color: $primaryColor;
    }
  }
}

table.strategy__table {
  font-family: $Pangram;
  margin-bottom: 0;
  thead,
  tbody {
    th ,td {
      font-weight: 500;
      line-height: 17px;
      color: $primaryColor;
      padding: 10px 35px;
      vertical-align: middle;
      border-top: 0px solid #ddd;
      border-bottom: 0px solid #ddd;
      white-space: nowrap;
    }
  }
  thead {
    background-color: #FAFAFA;
    th {
      font-size: 14px;
    }
  }
  tbody {
    tr {
      &:nth-child(even) {
        background-color: #FAFAFA;
      }
    }
    td {
      font-size: 16px;
    }
  }
}

.other__list__info {
  > li {
    display: flex;
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
    .info__block {
      // min-width: 250px;
      margin-bottom: 0;
      p {
        word-break: break-all;
      }
    }
    .material-textfield {
      width: 100%;
      max-width: 317px;
      margin-bottom: 0;
    }
  }
  .other-review-date {
    display: flex;
    align-items: center;
  }
  .app__installed {
    width: 100%;
    display: flex;
    align-items: center;
    .btn__send {
      margin-left: 20px;
      width: 100px;
    }
  }
}

// Strategist
.card__administrator {
  padding: 30px;
  border: 2px solid transparent;
  @media (max-width: 767px){
    padding: 15px;
  }
  &:hover,
  &:focus {
    border: 2px solid $primaryColor;
  }
  .card__administrator__title {
    font-family: $Pangram;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: $primaryColor;
    margin-bottom: 11px;
  }
  .card__administrator__subtitle {
    font-family: $Pangram;
    font-size: 14px;
    line-height: 17px;
    color: $primaryColor;
    margin-bottom: 9px;
    max-width: 250px;
  }
  .card__administrator__count {
    font-family: $Pangram;
    font-weight: 800;
    font-size: 48px;
    line-height: 58px;
    color: $primaryColor;
  }
  .card__administrator__image {
    position: absolute;
    right: 30px;
    bottom: 20px;
    img {
      @media (max-width: 667px) {
        max-width: 100px;
      }
    }
  }
}
.tasklist__following__tags {
  font-family: $Pangram;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #979797;
  width: 100%;
  // max-width: 626px;
  @media (min-width: 768px) and (max-width: 1024px) {
    // max-width: 100%;
    margin-top: 15px;
  }
  @media (max-width: 767px){
    margin-bottom: 15px;
    margin: 15px 0;
  }
}
.tags__view_all {
  color: $primaryColor;
  cursor: pointer;
  padding: 0 5px;
  display:inline-block;
}
.strategist__pages__tile {
  .card__tile {
    padding: 30px 26px;
  }
  .card__tile__title {
    margin-bottom: 0;
  }
}

.more__menu {
  background-color: transparent;
  .ant-dropdown-menu {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
    border-radius: 6px;
    .ant-dropdown-menu-item {
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      color: $primaryColor;
      &.activate {
        color: #00e700;
      }
      &.disable {
        color: #d0021b;
      }
    }
  }
}
.more__menu__overlay {
  // &::after {
  //   content: '';
  //   width: 0;
  //   height: 0;
  //   border-left: 7px solid transparent;
  //   border-right: 7px solid transparent;
  //   border-bottom: 12px solid #E5E5E5;
  //   position: absolute;
  //   top: -12px;
  //   right: 6px;
  // }
  .ant-dropdown-menu {
    font-family: $Pangram;
    background-color: #E5E5E5;
    border-radius: 6px;
    min-width: 138px;
    .ant-dropdown-menu-item {
      font-weight: $medium;
      font-size: 14px;
      color: #979797;
      border-bottom: 1px solid #B4B4B4;
      &:last-child {
        border-bottom: 0 solid #B4B4B4;
      }
    }
  }
}

.table__nested__main {
  tbody > tr {
    > td {
      font-size: 16px;
    }
  }
}
.table__nested {
  font-family: $Pangram;
  margin-bottom: 0;
  thead {
    background-color: #FAFAFA;
    tr {
      th {
        font-weight: bold;
        font-size: 12px;
        line-height: 14px;
        color: #979797;
        padding: 5px 20px;
      }
    }
  }
  tbody tr {
    &:nth-child(even) {
      background-color: transparent;
    }
    td {
      font-size: 14px;
      height: auto;
      padding: 9px 20px;
    }
  }
  .time {
    font-weight: 500;
    font-size: 10px;
    line-height: 20px;
    color: #979797;
    display: block;
  }
}

.table__schools {
  font-family: $Pangram;
  margin-bottom: 0;
  margin-left: -25px;
  margin-right: -25px;
  thead {
    tr {
      th {
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: $primaryColor;
        padding: 12px 25px;
        border-top: 1px solid #E5E5E5 !important;
        border-bottom: 1px solid #E5E5E5;
      }
    }
  }
  tbody tr {
    &:nth-child(even) {
      background-color: #FAFAFA;
    }
    td {
      font-weight: 500;
      font-size: 16px;
      height: auto;
      padding: 18px 25px;
      border-top: 0px solid #E5E5E5;
    }
  }
}
.insights__list {
  font-family: $Pangram;
  li {
    padding: 15px 25px;
    border-bottom: 1px solid #D8D8D8;
    .title {
      font-weight: bold;
      font-size: 16px;
      color: $primaryColor;
    }
    .title-link {
      font-size: 16px;
      line-height: 29px;
      text-decoration: underline;
      color: $secondaryColor;
    }
    .time {
      font-size: 14px;
      color: #979797;
    }
  }
}
.early__dismissal__list {
  font-family: $Pangram;
  font-size: 16px;
  line-height: 29px;
  color: $primaryColor;
  padding: 15px 25px;
  li {

  }
}

.table__cheat__sheet {
  font-family: $Pangram;
  margin-bottom: 0;
  .info__block {
    margin-bottom: 0;
    label {
      font-size: 14px;
    }
    p {
      color: $primaryColor;
    }
  }
  thead {
    tr {
      th {
        font-weight: bold;
        font-size: 16px;
        color: $primaryColor;
        padding: 20px 45px;
        border-right: 1px solid #E5E5E5;
        border-bottom: 1px solid #E5E5E5;
        &:last-child {
          border-right: 0 solid #E5E5E5;
        }
      }
    }
  }
  tbody tr {
    td {
      font-weight: 500;
      font-size: 16px;
      height: auto;
      padding: 18px 45px;
      border-right: 1px solid #E5E5E5;
      border-top: 1px solid #E5E5E5;
      &:last-child {
        border-right: 0 solid #E5E5E5;
      }
    }
  }
}
.note__text {
  font-family: $Pangram;
  font-size: 14px;
  line-height: 17px;
  color: $primaryColor;
}

.background__profile__add {
  padding: 15px 25px;
  border-top: 1px solid #E5E5E5;
}
.background__information__wrapper {
  border: 1px solid #D8D8D8;
  border-radius: 4px;
  .background__information__textarea {
    font-family: $Pangram;
    border: 0;
    resize: none;
    padding: 15px 20px;
  }
  .background__information__cta {
    padding: 13px;
    text-align: right;
  }
}

.title__section {
  font-family: $Pangram;
  font-weight: bold;
  font-size: 22px;
  line-height: 26px;
  color: $primaryColor;
  @media (max-width: 1024px) {
    margin-bottom: 20px;
  }
}
.title__sub__section {
  font-family: $Pangram;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: $primaryColor;
}

.reports__title {
  font-family: $Pangram;
  font-weight: bold;
  font-size: 22px;
  line-height: 26px;
  color: $primaryColor;
  margin: 0 0 13px
}
.reports__title__count {
  float: right;
  font-family: $Pangram;
  font-size: 42px;
  color: $secondaryColor;
  span {
    font-size: 22px;
  }
}
.reports__subtitle {
  font-family: $Pangram;
  font-size: 16px;
  line-height: 19px;
  color: #979797;
  margin: 0 0 13px
}
.btn__export {
  color: $secondaryColor;
  background-color: transparent;
  font-weight: 500;
  padding: 6px 0;
  &:hover,
  &:focus {
    color: $secondaryColor;
  }
}

.table__fixed {
  font-family: $Pangram;
  border-radius: 6px;
  overflow: hidden;
  .ant-table-header {
    .ant-table-cell {
      color: $primaryColor;
      background: $white;
      border-bottom: 1px solid #E5E5E5;
      &.ant-table-column-has-sorters {
        &:hover {
          background: $white;
        }
        .ant-table-column-sorters {
          padding: 0;
        }
        .ant-table-column-sorter-up.active,
        .ant-table-column-sorter-down.active {
          color: $primaryColor;
        }
      }
    }
  }
  .ant-table-header,
  .ant-table-tbody {
    .ant-table-cell {
      padding: 13px 36px;
    }
  }
  .ant-table-tbody {
    .ant-table-cell {
      font-weight: 500;
      font-size: 16px;
      color: $primaryColor;
    }
  }
  .ant-pagination.ant-table-pagination {
    margin-right: 15px;
  }
}

.ant-pagination {
  font-family: $Pangram;
  .ant-pagination-disabled {
    display: none;
  }
  .ant-pagination-item {
    font-family: $Pangram;
    &:hover,
    &:focus {
      border-color: $primaryColor;
      a {
        color: $primaryColor;
      }
    }
  }
  .ant-pagination-item-active {
    color: $white;
    background: $primaryColor;
    border-color: $primaryColor;
    a,
    &:focus a,
    &:active a,
    &:focus a {
      color: $white;
      &:hover {
        color: $white;
      }
    }
  }
  .ant-pagination-jump-prev,
  .ant-pagination-jump-next {
    .ant-pagination-item-link-icon {
      color: $primaryColor;
    }
  }
  .anticon {
    vertical-align: text-top;
  }
  .ant-pagination-prev,
  .ant-pagination-next {
    .ant-pagination-item-link {
      &:hover,
      &:focus {
        border-color: $primaryColor;
      }
    }
  }
}

.selected__strategist__list {
  background: #FFFFFF;
  box-shadow: 5px 5px 20px #E5E5E5;
  -webkit-box-shadow: 5px 5px 20px #E5E5E5;
  -moz-box-shadow: 5px 5px 20px #E5E5E5;
  border-radius: 6px;
  margin-bottom: 34px;
  > li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 14px 20px 33px;
    border-bottom: 1px solid #D8D8D8;
    &:last-child {
      border-bottom: 0 solid #D8D8D8;
    }
    .strategist__title {
      font-family: $Pangram;
      font-weight: 500;
      font-size: 16px;
      color: $primaryColor;
    }
  }
}

.status__switch {
  font-family: $Pangram;
  font-weight: 500;
  font-size: 16px;
  color: $primaryColor;
  .ant-switch {
    margin: 0 10px;
    background-color: rgba(0, 0, 0, 0.25);
    &.ant-switch-checked {
      background-color: $secondaryColor;
    }
  }
  .status__switch__text {
  }
  .status {
    color:  rgba(0, 0, 0, 0.25);
    &.yes {
      color: $secondaryColor;
    }
  }
}

label.label__text__label {
  font-family: $Pangram;
  font-weight: bold;
  font-size: 14px;
  line-height: 23px;
  color: $primaryColor;
  margin-bottom: 10px;
}

// Checkbox
.checkbox__list {
  &.checkbox-list-grid {
    > li {
      vertical-align: top;
      display: inline-block;
      width: calc(33.333% - 21px);
      @media (max-width: 667px) {
        width:100%;
      }
    }
  }
  &.checkbox-list-grid-2 {
    > li {
      vertical-align: top;
      display: inline-block;
      width: calc(50% - 21px);
      @media (max-width: 767px) {
        width: 100%
      }
    }
  }
  &.checkbox-list-grid-3 {
    > li {
      vertical-align: top;
      display: inline-block;
      width: calc(33.333% - 21px);
      margin-bottom: 15px;
      @media (max-width: 767px) {
        width: 100%
      }
    }
  }
  &.checkbox-list-grid-4 {
    > li {
      vertical-align: top;
      display: inline-block;
      width: calc(25% - 21px);
      margin-bottom: 15px;
      @media (max-width: 767px) {
        width: 100%
      }
    }
  }
  &.vertical {
    > li {
      margin-bottom: 17px;
    }
  }
  &.horizontal {
    > li {
      display: inline-block;
      margin-right: 21px;
    }
  }
  &.checkbox-grid-2 {
    > li {
      display: inline-block;
      width: calc(50% - 21px);
      margin-bottom: 15px;
    }
  }

}
.custom__checkbox {
  display: block;
  position: relative;
  padding-left: 42px;
  margin-bottom: 0;
  cursor: pointer;
  font-family: $Pangram;
  font-weight: $normal;
  color: $primaryColor;
  font-size: 16px;
  line-height: 26px;
  .custom__checkbox__input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
}
.custom__checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 26px;
  width: 26px;
  background-color: #fff;
  border: 1px solid #D8D8D8;
}
.custom__checkbox {
  .custom__checkbox__input:checked ~ .custom__checkmark {
    background-color: $primaryColor;
    border: 1px solid $primaryColor;
  }
}
.custom__checkmark:after {
  content: '';
  position: absolute;
  display: none;
}
.custom__checkbox {
  .custom__checkbox__input:checked ~ .custom__checkmark:after {
    display: block;
  }
  .custom__checkmark:after {
    left: 10px;
    top: 4px;
    width: 6px;
    height: 13px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
}
// Checkbox

// React ReactMultiSelectCheckboxes
.multiselect__checkboxes__field {
  position: relative;
  font-family: $Pangram;
  min-width: 250px;
  .multiselect__checkboxes__field__label {
    font-family: $Pangram;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    color: $primaryColor;
    position: absolute;
    top: 8px;
    left: 15px;
  }
  &.multiselect__checkboxes__field__lg {
    button[class*="css-"] {
      min-height: 66px;
    }
  }
  button[class*="css-"] {
    border: 2px solid #E5E5E5;
    min-height: 54px;
    border-radius: 4px;
    color: $primaryColor;
    padding: 20px 40px .375rem 15px;
    // padding-top: 20px;
    font-size: 16px;
    box-shadow: none;
    background: #fff url("/img/img_v2/select-icon.svg") no-repeat calc(100% - 16px) center;
    background-size: 16px;
    > span[class*="css-1v99tuv"] {
      text-transform: capitalize;
    }
    .css-1gpjby2 {
      display: none;
    }
    & + div {
      width: 100%;
      margin-top: 3px !important;
      // position: relative !important;
      > div {
        border-radius: 4px;
        background: #fff;
        box-shadow: 5px 10px 30px #E5E5E5;
        > div {
          display: flex;
          align-items: center;
          border-bottom: 1px solid #E5E5E5;
          + div {
            // background-color: red;
            > div {
              width: 100%;
              max-height: 250px;
              div {
                cursor: pointer;
                color: $primaryColor;
                font-weight: 400;
                font-size: 14px;
                padding: 6px 12px;
                background-color: transparent;
                position: relative;
                vertical-align: middle;
                min-width: 150px;

                input[type="checkbox"] {
                  -webkit-appearance: none;
                  -moz-appearance: none;
                  appearance: none;
                  margin-right: 25px !important;
                  cursor: pointer;
                  &::before {
                    content: "";
                    position: absolute;
                    top: 6px;
                    left: 10px;
                    height: 20px;
                    width: 20px;
                    cursor: pointer;
                    border: 2px solid #e5e5e5;
                  }
                  &:active,
                  &:hover {
                    background-color: transparent;
                  }
                }
              }
              .css-1qprcsu-option {
                input[type="checkbox"]{
                  &::before {
                    content: "";
                    background-color: $primaryColor;
                    border: 2px solid $primaryColor;
                    height: 20px;
                    width: 20px;
                  }
                  &::after {
                    position: absolute;
                    content: '';
                    width: 6px;
                    height: 12px;
                    border: solid #fff;
                    border-width: 0 2px 2px 0;
                    transform: rotate(45deg);
                    top: 8px;
                    left: 17px;
                  }
                }

              }

            }
          }
        }
      }
    }
  }

}

// React ReactMultiSelectCheckboxes

// Radiobox
.radiobox__list {
  &.vertical {
    > li {
      margin-bottom: 10px;
    }
  }
}
.custom__radiobox {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 0;
  cursor: pointer;
  font-family: $Pangram;
  color: $primaryColor;
  font-weight: $normal;
  font-size: 16px;
  line-height: 18px;
  .custom__radiobox__input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  .custom__checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    background-color: #fff;
    border: 1px solid #D8D8D8;
    border-radius: 50px;
  }

  .custom__radiobox__input:checked ~ .custom__checkmark {
    background-color: $primaryColor;
    border: 1px solid $primaryColor;
  }

  // .custom__checkmark:after {
  //   content: '';
  //   position: absolute;
  //   display: none;
  // }
  //
  // .custom__radiobox__input:checked ~ .custom__checkmark:after {
  //   display: block;
  // }
  // .custom__checkmark:after {
  //   left: 10px;
  //   top: 5px;
  //   width: 6px;
  //   height: 13px;
  //   border: solid white;
  //   border-width: 0 2px 2px 0;
  //   transform: rotate(45deg);
  // }

}
// Radiobox

.tabs__control__links {
  border-bottom: 1px solid #D8D8D8;
  display: flex;
  margin: 15px 0;
  width: 100%;
  white-space: nowrap;
  @media (max-width: 1024px) {
    overflow-y: hidden;
    overflow-x: auto;
  }
  > li {
    margin-right: 15px;
    @media (max-width: 1024px) {
      margin-right: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
  .tabs__link {
    color: $primaryColor;
    font-family: $Pangram;
    font-size: 16px;
    padding: 23px 15px;
    font-weight: $medium;
    position: relative;
    width: 100%;
    display: inline-block;
    @media (max-width: 1024px) {
      white-space: nowrap;
    }
    &:focus,
    &:hover {
      color: $primaryColor;
    }
    &.active::after {
      content: '';
      height: 2px;
      width: 100%;
      display: block;
      position: absolute;
      left: 0;
      bottom: -1px;
      background-color: $primaryColor;
    }
  }
}

.card__inner {
  padding: 12px 45px;
}
.small__hr {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: -45px;
  margin-right: -45px;
  @media (max-width: 767px) {
    margin-left: -15px;
    margin-right: -15px;
  }
}
.info__block__wrap {
  position: relative;
}
.info__block__wrap__edit {
  position: absolute;
  right: 0;
  top: 12px;
}
label.label__text__sm {
  font-family: $Pangram;
  color: $primaryColor;
  margin-bottom: 10px;
}
.text__desciption {
  font-family: $Pangram;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 0;
}

.edit__data__inline {
  display: flex;
  align-items: center;
  width: 100%;
  .material-textfield {
    // width: 50%;
    margin-right: 15px;
    flex-grow: 1;
    .form-control {
      resize: none;
    }
  }
}
.edit__data__inline__cta {
  // width: 50%;
  display: flex;
  .btn-cancel,
  .btn-save {
    min-height: 66px;
  }
  .btn-cancel {
    color: #fff;
    background-color: #B4B4B4;
    min-width: 180px;
    margin-right: 15px;
  }
  .btn-save {
    min-width: 180px;
  }
}

.custom__table__simple {
  position: relative;
  z-index: 1;
  margin-bottom: 0;
  background: #FFFFFF;
  box-shadow: 5px 5px 20px #E5E5E5;
  -webkit-box-shadow: 5px 5px 20px #E5E5E5;
  -moz-box-shadow: 5px 5px 20px #E5E5E5;
  border-radius: 6px;
  &.table td,
  &.table th{
    font-family: $Pangram;
    border-top: 0px solid #dee2e6;
    white-space: nowrap;
  }
  thead {
    tr {
      th {
        font-weight: $medium;
        font-size: 14px;
        line-height: 17px;
        color: $primaryColor;
        border-bottom: 1px solid #E5E5E5;
        padding: 12px 20px;
      }
    }
  }
  tbody {
    tr {
      td {
        font-weight: $medium;
        font-size: 16px;
        color: $primaryColor;
        height: 58px;
        vertical-align: middle;
        padding: 10px 20px;
        a {
          color: $primaryColor;
          text-decoration: underline;
        }
      }
    }
  }
  tbody tr {
    &:nth-child(even) {
      background-color: #FAFAFA;
    }
  }
  .table-column-title,
  .table-column-sorter {
    display: table-cell;
    vertical-align: middle;
  }
  .table-column-sorter{
    svg {
      margin-left: 5px;
    }
  }
}

.heading__text {
  font-family: $Pangram;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #979797;
  margin-bottom: 15px;
}
.selected__towns__table {
  tbody tr {
    border-bottom: 1px solid #D8D8D8;
    &:last-child {
      border-bottom: 0px solid #D8D8D8;
    }
  }
  tbody tr td {
    padding: 10px 10px;
    &:first-child {
      width: 420px;
      padding: 10px 20px;
    }
  }
  .small__text {
    font-weight: 500;
    font-size: 12px;
    color: $secondaryColor;
    margin-top: 5px;
  }
}

.attom__schools__search {
  padding: 15px 30px;
  margin: 0;
  border-bottom: 1px solid #D8D8D8;
}
.attom__schools {
  height: calc(100vh - 106px - 54px);
  overflow: auto;
}
.attom__public__schools__list {
  > li {
    font-family: $Pangram;
    font-size: 16px;
    line-height: 29px;
    color: $primaryColor;
    border-bottom: 1px solid #D8D8D8;
    padding: 15px 30px;
    display: block;
    position: relative;
    span {
      font-weight: bold;
    }
    .round-icon {
      position: absolute;
      top: 50%;
      right: 32px;
      transform: translateY(-50%);
      width: 50px;
      height: 50px;
      border-radius: 50px;
      background: #FFFFFF;
      border: 1px solid #D8D8D8;
      z-index: 1;
      cursor: pointer;
    }
    &.active {
      .round-icon {
        background: $primaryColor;
      }
    }
  }
}

// AI Recommended Towns
.ai__recommended__towns {
  height: calc(100vh - 106px - 75px);
  overflow: auto;
}
.ai__recommended__towns__list {
  > li {
    font-family: $Pangram;
    font-size: 16px;
    line-height: 29px;
    color: $primaryColor;
    border-bottom: 1px solid #D8D8D8;
    padding: 26px 30px;
    display: block;
    position: relative;
    span {
      font-weight: bold;
    }
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      right: 32px;
      transform: translateY(-50%);
      width: 50px;
      height: 50px;
      border-radius: 50px;
      background: #FFFFFF;
      border: 1px solid #D8D8D8;
      z-index: 1;
    }
    &.active {
      &:after {
        background: $primaryColor;
      }
    }
  }
}
.ai__recommended__towns__bottom {
  padding: 20px 30px;
}

// AI Recommended Towns

// Drawer
.drawer__main {
  .ant-drawer-content-wrapper {
    max-width: 600px;
  }
  .ant-drawer-body {
    padding: 0;
  }
}
.drawer__header {
  padding: 12px 30px;
  border-bottom: 1px solid #E5E5E5;
  position: relative;
  height: 75px;
  .drawer__header__title {
    font-family: $Pangram;
    font-weight: bold;
    font-size: 22px;
    line-height: 26px;
    color: $primaryColor;
    margin-bottom: 0;
    @media (max-width: 767px){
      font-size: 18px;
    }
  }
  .drawer__header__subtitle {
    font-family: $Pangram;
    font-size: 14px;
    line-height: 17px;
    color: #979797;
    margin-top: 0.5em;
    margin-bottom: 0;
  }
  .drawer__header__close {
    position: absolute;
    top: 25px;
    right: 30px;
    z-index: 1;
  }
}
.drawer__body {
  padding: 30px;
}
.drawer__body__inner {
  height: calc(100vh - 135px);
  display: flex;
  align-items: center;
  &.height__remove {
    height: calc(100vh - 265px);
  }
  .blank__state__text {
    font-size: 16px;
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
  }
}
// Drawer

.stratgist__follow__list {
  margin: 20px 0;
  > li {
    display: inline-block;
    width: 204px;
    margin-right: 20px;
    &:last-child {
      margin-right: 0;
    }
    @media (max-width: 767px){
      width: 100%;
      margin-bottom: 10px;
    }
  }
  .stratgist__name {
    font-family: $Pangram;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    color: $primaryColor;
    margin-bottom: 5px;
  }
}

.town__insights {
  margin-left: -45px;
  margin-right: -45px;
  .insights__list {
    > li {
      position: relative;
      &:last-child {
        border: 0;
      }
    }
  }
  .early__dismissal__list {
    position: relative;
  }
  .town__insights__cta {
    position: absolute;
    top: 20px;
    right: 30px;
    .btn {
      margin-left: 20px;
    }
  }
}

// Loader Spinner
.custon__spinner {
  &.custon__spinner__small {
    width: 40px;
    height: 40px;
    border: 5px solid #f3f3f3;
    border-top: 5px solid $secondaryColor;
    margin: -20px 0 0 -20px;
  }
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  width: 60px;
  height: 60px;
  margin: -30px 0 0 -30px;
  border: 7px solid #f3f3f3;
  border-top: 7px solid $secondaryColor;
  border-radius: 50%;
  -webkit-animation: spin .5s linear infinite;
  animation: spin .5s linear infinite;
}
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
// Loader Spinner


// single Radio
.single__radio {
  display: inline-block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 0;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  height: 35px;
  width: 35px;
  .single__radio__input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
}
.single__checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 35px;
  width: 35px;
  background-color: #fff;
  border: 2px solid #E5E5E5;
  border-radius: 50px;
}

.single__radio {
  .single__radio__input:checked ~ .single__checkmark {
    background-color: $secondaryColor;
    border: 2px solid $secondaryColor;
  }
}

// .single__checkmark:after {
//   content: "";
//   position: absolute;
//   display: none;
// }
//
// .single__radio {
//   .single__radio__input:checked ~ .single__checkmark:after {
//     display: block;
//   }
//   .single__checkmark:after {
//     top: 9px;
//     left: 9px;
//     width: 8px;
//     height: 8px;
//     border-radius: 50%;
//     background: white;
//   }
// }

// single Radio

// Emails
.emails__header {
  font-family: $Pangram;
  .email__title {
    font-size: 16px;
    font-weight: bold;
    line-height: 23px;
    color: $primaryColor;
  }
  .email__emailinfo {
    font-weight: 400;
    font-size: 12px;
  }
  .email__time {
    font-weight: 400;
    font-size: 12px;
    margin-bottom: 0;
  }
}
.emails__custom__collapse {
  font-family: $Pangram;
  &.ant-collapse-borderless {
    background-color: transparent;
    > .ant-collapse-item {
      border-bottom: 1px solid #e5e5e5;
      &:last-child {
        border-bottom: 0 solid #e5e5e5;
      }
    }
    .ant-collapse-header {
      padding: 12px 35px;
      padding-right: 55px;
      .ant-collapse-arrow {
        right: 35px;
      }
    }
    .ant-collapse-content {
      color: $primaryColor;
      border-top: 1px solid #e5e5e5;
      > .ant-collapse-content-box {
        padding: 16px 35px !important;
      }
    }
  }
}
// Emails

// Chats
.chats__start__bottom{
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: calc(100% - 75px);
}
.chats__select__agents {
  padding: 15px 30px;
  margin-bottom: auto;
}
.chats__container {
  overflow: auto;
  padding: 15px 30px;
  .chats__wrapper {
    .chats__wrapper__chats__list {
      > li {
        margin-bottom: 20px;
        &:last-child {
          margin-bottom: 20px;
        }
        &.has__avatar {
          padding-left: 42px;
          .chats__card__header {
            margin-left: -42px;
          }
          + .btn__view_more_chat {
            margin-left: 42px;
          }
        }
        &.active {
          .chats__content {
            background: #D8D8D8;
          }
        }
      }
    }
    .chats__card__header {
      display: flex;
      align-items: center;
      min-height: 42px;
      .chats__card__avatar {
        background: #b6bbbe;
        border-radius: 50%;
        color: #fff;
        display: inline-block;
        font-family: $Pangram;
        font-size: 16px;
        font-weight: 600;
        height: 42px;
        letter-spacing: 1.5px;
        line-height: 42px;
        margin-right: 13px;
        overflow: hidden;
        position: relative;
        text-align: center;
        vertical-align: middle;
        white-space: nowrap;
        width: 42px;
        > img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 100px;
          display: block;
        }
        .string__avatar {
          position: absolute;
          left: calc(50% + 1.5px);
          -webkit-transform-origin: 0 center;
          -ms-transform-origin: 0 center;
          transform-origin: 0 center;
          -webkit-transform: scale(1) translateX(-50%);
          -ms-transform: scale(1) translateX(-50%);
          transform: scale(1) translateX(-50%);
        }
      }
      .chats__card__name,
      .chats__card__time {
        font-family: $Pangram;
        font-weight: bold;
        font-size: 16px;
        line-height: 22px;
        color: $primaryColor;
      }
      .chats__card__time {
        font-size: 12px;
        font-weight: $normal;
        margin-left: 5px;
      }
    }
    .chats__content {
      font-family: $Pangram;
      white-space: pre-line;
      font-size: 16px;
      line-height: 22px;
      color: $primaryColor;
      background: #FFFFFF;
      box-shadow: 5px 5px 20px #E5E5E5;
      -webkit-box-shadow: 5px 5px 20px #E5E5E5;
      -moz-box-shadow: 5px 5px 20px #E5E5E5;
      -webkit-box-shadow: 5px 5px 20px #E5E5E5;
      -webkit-box-shadow: 5px 5px 20px #E5E5E5;
      -moz-box-shadow: 5px 5px 20px #E5E5E5;
      -moz-box-shadow: 5px 5px 20px #E5E5E5;
      -webkit-box-shadow: 5px 5px 20px #E5E5E5;
      -moz-box-shadow: 5px 5px 20px #E5E5E5;
      border-radius: 6px;
      padding: 15px 20px;
      // word-break: break-all;
    }
    .delivered,
    .undelivered {
      font-family: $Pangram;
      font-weight: 500;
      font-size: 14px;
      margin: 8px 0;
    }
    .delivered {
      color: $secondaryColor;
    }
    .undelivered {
      color: #D51236;
    }
    .chats__card__edit {
      margin-top: 15px;
      .chats__card__textarea__edit {
        font-family: $Pangram;
        font-size: 16px;
        color: $primaryColor;
        padding: 17px 20px;
        width: 100%;
        background: #FFFFFF;
        border-radius: 4px;
        border: 1px solid #D8D8D8;
        resize: vertical;
        margin-bottom: 5px;
        &:focus {
          outline: none;
          border: 1px solid $primaryColor;
        }
      }
      .chats__card__edit__close {
        width: 18px;
        cursor: pointer;
      }
    }
    .chats__card__cta {
      margin-top: 10px;
    }
    .btn__edit_chat,
    .btn__delete_chat {
      font-family: $Pangram;
      font-weight: $medium;
      font-size: 14px;
      line-height: 22px;
      text-decoration: underline;
      background-color: transparent;
      color: #B4B4B4;
      padding: 0;
      margin-right: 15px;
      &:hover ,
      &:focus {
        color: $secondaryColor;
      }
    }
    .btn__view_more_chat {
      background: transparent;
      font-family: $Pangram;
      font-weight: bold;
      font-size: 14px;
      line-height: 22px;
      color: $secondaryColor;
      padding: 5px 0;
      margin: 10px 0;
    }
  }
}
.chats__container__teatarea {
  width: calc(100% - 60px);
  background: #FFFFFF;
  border: 1px solid #D8D8D8;
  border-radius: 4px;
  margin: 5px 30px 5px;
  .chats__sectiion__input {
    font-family: $Pangram;
    font-size: 16px;
    color: $primaryColor;
    min-height: 110px;
    background: transparent;
    width: 100%;
    border: 0;
    resize: none;
    padding: 15px 20px 0;
    display: block;
    &:focus {
      outline: none;
    }
  }
  .chats__section__cta {
    width: 100%;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    .btn__send {
      margin-right: 15px;
      min-width: 127px;
      min-height: 48px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    label {
      cursor: pointer;
    }
  }
}
// Chats

.ranking__list {
  > li {
    font-family: $Pangram;
    font-weight: 500;
    font-size: 16px;
    color: $primaryColor;
    padding: 15px 0;
  }
}

.share__insights__container {
  height: calc(100% - 75px);
}
.share__insights__select {
  padding: 15px 30px;
}
.share__insights__list {
  font-family: $Pangram;
  height: calc(100% - 174px);
  overflow: auto;
  li {
    font-size: 16px;
    padding: 15px 120px 15px 30px;
    border-bottom: 1px solid #D8D8D8;
    position: relative;
    &:last-child {
      border-bottom: 0 solid #D8D8D8;
    }
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      right: 32px;
      transform: translateY(-50%);
      width: 50px;
      height: 50px;
      border-radius: 50px;
      background: #FFFFFF;
      border: 1px solid #D8D8D8;
      z-index: 1;
      cursor: pointer;
    }
    &.active {
      &:after {
        background: $primaryColor;
      }
    }
  }
  .title {
    font-weight: bold;
    font-size: 16px;
    color: $primaryColor;
  }
  .title-link {
    font-size: 16px;
    line-height: 29px;
    text-decoration: underline;
    color: $secondaryColor;
  }
  .time {
    font-size: 14px;
    color: #979797;
  }
}
.share__insights__bottom {
  padding: 15px 30px;
}
.btn__link.btn__edit__town__characteristics {
  color: $secondaryColor;
  text-decoration: none;
}
.emails__list {
  // overflow: auto;
  // height: 520px;
  margin-top: 18px;
  > li {
    font-family: $Pangram;
    font-size: 16px;
    padding: 5px 0;
    border-top: 1px solid #e5e5e5;
    .clipcopy-icon {
      margin-left: 15px;
      cursor: pointer;
    }
  }
}

.btn__start__time,
.btn__stop__time {
  min-width: 150px;
  // margin-right: 15px;
  img {
    width: 18px;
    margin-right: 10px;
    vertical-align: top;
  }
}
.btn__start__time {
  color: $white;
  background-color: $secondaryColor;
  &:hover,
  &:focus {
    color: $white;
  }
}
.btn__stop__time {
  color: $white;
  background-color: $red;
  &:hover,
  &:focus {
    color: $white;
  }
}

// Your Agents
.your__agents__container__responsive {
  overflow: auto;
}
.your__agents__container {
  font-family: $Pangram;
  width: 100%;
  margin-bottom: 30px;
  @media (max-width: 767px){
    overflow: auto;
    width: 640px;
  }
  .your__agents__list__header {
    display: flex;
    align-items: center;
    width: 100%;
    > li {
      width:  calc(100% / 4);
      padding: 12px 20px;
      @media  (min-width: 768px) and (max-width: 1024px){
        width:  calc(100% / 3);
      }
      @media (max-width: 767px){
        width:  calc(640px / 3);
      }
      .table-column-title {
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: $primaryColor;
      }
      .table-column-sorter {
        cursor: pointer;
        svg {
          margin-left: 8px;
        }
      }
    }
  }
  .your__agents__list__body {
    .div__tr__rows {
      width: 100%;
      // border-bottom: 1px solid #D8D8D8;
      background: #FFFFFF;
      box-shadow: 5px 5px 20px #E5E5E5;
      -webkit-box-shadow: 5px 5px 20px #E5E5E5;
      -moz-box-shadow: 5px 5px 20px #E5E5E5;
      border-radius: 6px;
      margin-bottom: 30px;
      // &:last-child {
      //   border-bottom: 0 solid #D8D8D8;
      // }
    }
    .div__rows {
      display: flex;
      align-items: center;
      width: 100%;
      .div__column {
        font-weight: 500;
        font-size: 16px;
        width: calc(100% / 4);
        padding: 20px;
        @media  (min-width: 768px) and (max-width: 1024px){
          width:  calc(100% / 3);
        }
        @media (max-width: 767px){
          white-space: nowrap;
          width:  calc(640px / 3);
        }
      }
    }
    .div__nested__rows {
      display: flex;
      align-items: center;
      width: 100%;
      &.div__nested__rows__header {
        background-color: #FAFAFA;
        .div__nested__column {
          font-weight: bold;
          font-size: 12px;
          line-height: 14px;
          color: #979797;
          padding: 5px 20px;
        }
      }
      .div__nested__column {
        font-weight: 500;
        font-size: 14px;
        width: calc(100% / 4);
        padding: 9px 20px;
        // white-space: nowrap;
      }
      .time {
        font-weight: 500;
        font-size: 10px;
        line-height: 20px;
        color: #979797;
        display: block;
      }
    }
  }
}
// Your Agents


// Dropzone
.dropzone {
  width: 100%;
  cursor: pointer;
  display: inline-block;
  margin-bottom: 20px;
  &.dropzone-sm {
    max-width: 500px;
    margin: 0 auto;
    .dropzone-inner-area  {
      height: 176px;
    }
  }
  .upload-container {
    cursor: pointer;
  }
  .dropzone-inner-area {
    width: 100%;
    height: 250px;
    @media (max-width: 767px) {
      height: 100%;
      padding: 30px 0;
    }
    background: #fff;
    border: 2px dashed #B4B4B4;;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    &:focus {
      outline: none;
    }
  }
  .upload-text {
    font-weight: $bold;
    font-size: 14px;
    line-height: 33px;
    text-align: center;
    color: $primaryColor;
    margin-bottom: 5px;
  }
  .btn-upload {
    min-width: 177px;
  }
}
// Dropzone

.uploaded__images__card {
  position: relative;
  margin-bottom: 30px;
  .uploaded__images__thumb__wrapp {
    position: relative;
    padding-top: 66.66%;
    height: 0;
    background: $white;
    border-radius: 6px;
    overflow: hidden;
    // border: 4px solid transparent;
    &.default__featured {
      border: 4px solid $secondaryColor;
    }
  }
  .uploaded__images__thumb{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 6px;
    object-fit: cover;
    transform-origin: center center;
  }
  .uploaded__images__card__info {
    padding: 5px 0;
    display: flex;
    align-items: center;
    .btn-rotate {
      min-width: 70px;
      min-height: 35px;
      font-size: 14px;
    }
    .btn-link-danger {
      font-size: 14px;
      color: $red;
      background-color: transparent
    }
    .btn-set-featured {
      font-size: 14px;
      color: $primaryColor;
      background-color: transparent;
      padding: 0;
      margin-left: 5px;
    }
    .btn-link {
      z-index: 1;
      padding: 6px;
      img {
        position: relative;
        z-index: -1;
      }
    }
  }
  .featured__label {
    font-family: $Pangram;
    position: absolute;
    top: 15px;
    left: 15px;
    z-index: 1;
    font-size: 16px;
    color: #fff;
    background-color: $secondaryColor;
    padding: 5px 10px;
    border-radius: 6px;
    font-weight: 500;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.22);
  }
}

.town__characteristics__cta {
  text-align: right;
  @media (max-width: 1024px){
    display: flex;
    justify-content: flex-end;
  }
}

.client__details__header__row {
  .client__details__header__cta {
    .btn__impersonate__user,
    .btn__schedule__appointment {
      min-width: 200px;
      @media (max-width: 767px){
        min-width: inherit;
        // width: 100%;
        margin-top: 15px;
      }
    }
    .btn__impersonate__user {
      margin: 0 15px;
    }
    .btn__schedule__appointment {

    }
    .client__details__header__cta__inline {
      // @media (max-width: 767px){
      //   display: flex;
      //   flex-wrap: wrap;
      // }
    }
  }
  .material-textfield {
    @media (max-width: 767px){
      margin-bottom: 15px;
    }
  }
}

// Text Color Css
.text__green {
  color: $secondaryColor !important;
}
// Text Color Css

// Administration Notifications
.administration__notificaton__modal {

}
.administration__notificaton__modal__overlay  {
  width: 100%;
  max-width: 585px;
  .ant-popover-inner {
    box-shadow: 5px 5px 20px #E5E5E5;
    -webkit-box-shadow: 5px 5px 20px #E5E5E5;
    -moz-box-shadow: 5px 5px 20px #E5E5E5;
    border-radius: 6px;
  }
  .ant-popover-inner-content {
    padding: 0;
  }
}
.administration__notificaton__container {
  .notificaton__container__header {
    border-bottom: 1px solid #D8D8D8;
    padding: 15px 0;
    position: relative;
    .notificaton__container__content{
      font-family: $Pangram;
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      color: $primaryColor;
      text-align: center;
    }
    .btn__close__notify {
      background-color: transparent;
      padding: 0;
      position: absolute;
      top: 12px;
      right: 22px;
      z-index: 1;
      svg {
        width: 16px;
      }
    }
  }
  .margin__ten {
    margin: 10px;
  }
  .padding__less {
    padding: 0 15px 0px !important;
  }
  .notificaton__container__body {
    padding: 0 25px 15px;
    overflow: auto;
    max-height: 400px;
    .notificaton__blank__text {
      font-family: $Pangram;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      color: #979797;
      text-align: center;
      padding: 30px 10px;
    }
    .btn__clear_all {
      font-family: $Pangram;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      text-decoration: underline;
      color: $primaryColor;
      margin: 6px 0;
      background-color: transparent;
      padding: 9px 0;
      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
  }
  .notificaton__list {
    // background: #FFFFFF;
    // box-shadow: 5px 5px 20px #E5E5E5;
    -webkit-box-shadow: 5px 5px 20px #E5E5E5;
    -moz-box-shadow: 5px 5px 20px #E5E5E5;
    // border-radius: 6px;
    margin-bottom: 20px;
    cursor: pointer;
    margin-left: 45px;
    > li {
      position: relative;
      background-color: #E5E5E5;
      margin-bottom: 30px;
      border-radius: 6px 6px 0 0;
      &:last-child {
        margin-bottom: 0;
      }
      &::before {
        content: '';
        width: 30px;
        height: 30px;
        background-color: #fff;
        border: 2px solid #D8D8D8;
        display: inline-block;
        border-radius: 50px;
        position: absolute;
        left: -45px;
        // top: 50%;
        top: 20px;
        transform: translateY(-50%);
      }
      &.active {
        &::before {
          background-color: $primaryColor;
          border: 2px solid $primaryColor;
        }
        // &:first-child {
        //   border-radius: 6px 6px 0 0;
        // }
        // &:last-child {
        //   border-radius: 0 0 6px 6px;
        // }
        background-color: $primaryColor;
        .notificaton__card {
          background-color: #fff;
        }
        .notificaton__title,
        .notificaton__delivery {
          color: #fff;
        }
      }
    }
    .notificaton__item {
      padding: 15px 24px;
      .notificaton__title {
        font-family: $Pangram;
        font-size: 14px;
        line-height: 23px;
        color: $primaryColor;
        margin-bottom: 10px;
      }
      .notificaton__card {
        font-family: $Pangram;
        font-size: 14px;
        line-height: 22px;
        color: $primaryColor;
        padding: 10px 20px;
        margin-bottom: 10px;
        background: #fff;
        border-radius: 6px;
        white-space: pre-line;
      }
      .notificaton__delivery {
        font-family: $Pangram;
        font-size: 12px;
        line-height: 23px;
        color: #111111;
      }
    }
  }
}
// Administration Notifications

.blank__table__card {
  background: #FFFFFF;
  box-shadow: 5px 5px 20px #E5E5E5;
  -webkit-box-shadow: 5px 5px 20px #E5E5E5;
  -moz-box-shadow: 5px 5px 20px #E5E5E5;
  border-radius: 6px;
  text-align: center;
  padding: 40px 20px;
  margin: 30px 0;
  .blank__table__card__inner{
    font-family: $Pangram;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #979797;
  }
}

.results__select__dropdown {
  display: inline-block;
  width: 125px;
}

.btn__link__gray {
  font-weight: 500;
  font-size: 16px;
  color: #979797;
  background-color: transparent;
  padding: 0;
}

.edit__user__head {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.towns__head {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @media (max-width: 1190px) {
    flex-wrap: wrap;
  }
  .btn__create__town__report {
    min-width: 200px;
    @media (max-width: 767px) {
      min-width: 170px;
    }
  }
  // .mr__15 {
  //   @media (max-width: 375px) {
  //     margin-right: 0 !important;
  //   }
  // }
  .material-textfield {
    @media (max-width: 715px) {
      margin-top: 15px;
    }
  }
  .sort__results {
    display: flex;
    align-items: center;
    @media (min-width: 1025px) and (max-width: 1190px) {
      margin-top: 15px;
    }
    @media (max-width: 715px) {
      width: 100%;
    }
  }
  .btn-filter {
    margin-right: 0;
  }
  .sort__by {
    width: 150px;
    margin-left: 15px;
    margin-right: 15px;
    @media (max-width: 715px) {
      width: 100%;
      margin-left: 0;
    }
  }
  .results__page {

  }
}

.client-status {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: $secondaryColor;
  margin-left: 10px;
}

.rating-strategist {
  font-size: 12px;
  line-height: 23px;
  color: $secondaryColor;
  margin-left: 5px;
}

.source__code__ad {
  font-family: $Pangram;
  font-size: 16px;
  line-height: 22px;
  color: $primaryColor;
  margin: 10px 0;
}

.leave-comment-count {
  width: 20px;
  height: 20px;
  border-radius: 50px;
  background-color: #CF0808;
  display: inline-block;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: #FFFFFF;
  margin-left: 4px;
}

// Strategist


// Please write any css above this comment area
// Repsonsive Csss mobile View css
.mob__view__container {
  @media (min-width: 767px){
    display: none;
  }
  &.mob__view__control {
    margin-left: -15px;
    margin-right: -15px;
  }
  .mob__client__status {
    > li {
      background: #FFFFFF;
      box-shadow: 5px 5px 20px #E5E5E5;
      -webkit-box-shadow: 5px 5px 20px #E5E5E5;
      -moz-box-shadow: 5px 5px 20px #E5E5E5;
      margin-bottom: 10px;
      a {
        font-family: $Pangram;
        font-weight: $medium;
        font-size: 14px;
        line-height: 17px;
        color: $secondaryColor;
        padding: 18px;
        display: flex;
        svg {
          margin-left: auto;
        }
      }
    }
  }
  .mob__heading__title {
    font-family: $Pangram;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: $primaryColor;
    margin: 20px 15px;
  }
  .client__name {
    font-family: $Pangram;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    color: $primaryColor;
    margin-bottom: 10px;
  }
  .client__budget {
    font-family: $Pangram;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: $secondaryColor;
    white-space: nowrap;
  }
  .client__rental__budget {
    font-weight: $bold;
    font-size: 12px;
    color: $primaryColor;
    margin-top: 4px;
  }
  .mob__custom__table {
    font-family: $Pangram;
    background-color: #fff;
    box-shadow: 5px 5px 20px #E5E5E5;
    -webkit-box-shadow: 5px 5px 20px #E5E5E5;
    -moz-box-shadow: 5px 5px 20px #E5E5E5;
    tr td {
      padding: 12px 22px;
      vertical-align: middle;
    }
  }
  .mob__follow__date {
    display: flex;
    align-items: center;
    .right-icon {
      margin-left: 12px;
    }
  }
}
.datepicker__overlay__follow-up-date {
  @media (max-width: 767px){
    left: inherit !important;
    right: 42px;
  }
}
.desktop__view {
  @media (max-width: 767px){
    display: none;
  }
}
.disable__text__decoration{
  text-decoration: none !important;
}
// mobile View css
// Repsonsive Csss mobile View css
// Please write any css above this comment area

.disable__cursor__pointer{
  cursor: default !important;
}
.inputwidth_town {
  width: 100%;
}
